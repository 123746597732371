import React, { useMemo, useEffect, forwardRef, useState } from "react";
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Config, ConfigEnum } from 'util/Config';
import { useForm, useFieldArray } from "react-hook-form";
import NavigationButton from 'components/buttons/NavigationButton';
import GeneralInfo from "./GeneralInfo";
import WallPanelCeilingFloor from "./WallPanelCeilingFloor";
import FrontReturnDoorPanelToeGuard from "./FrontReturnDoorPanelToeGuard";
import FacialCarPlatePanelControllerBox from "./FacialCarPlateFramePanelControllerBox";
import { Stepper , Step, StepLabel} from "@mui/material";
import PropTypes from 'prop-types';
import Check from '@mui/icons-material/Check';
import QontoConnector from "components/stepper/QConnector";
import QontoStepIconRoot from "components/stepper/QStepIcon";
function QontoStepIcon(props) {
    const { active, completed, className } = props;
    console.log(props);
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }
  
  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };


const CreateorUpdateModel = (props) =>{
  
    const state = useLocation()?.state;
    const [activeStep, setActiveStep] = useState(0);
    const steps = useMemo(() => {
        let temp = [];
        temp.push("1");
        temp.push("2");
        temp.push("3");
        temp.push("4");
       
        return temp;
      }, []);

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [elevatorId, setElevatorId] = useState(null);
    const [generalInfo, setGeneralInfo] = useState(null);
    const [step2, setStep2] = useState(null);
    const [step3, setStep3] = useState(null);
    const [step4, setStep4] = useState(null);
    const [step5, setStep5] = useState(null);
    useEffect(()=>{
      console.log(generalInfo);
    },[generalInfo]);
    const getStepContent = (step) => {
      switch (step) {
        case 0:
          return (
            <GeneralInfo {...{handleNext, handleBack, elevatorId, setElevatorId, generalInfo, setGeneralInfo}} />
          );
        case 1:
          return (
            <WallPanelCeilingFloor {...{handleNext, handleBack, elevatorId, step2, setStep2}} />
          );
        case 2:
          return (
            <FrontReturnDoorPanelToeGuard {...{handleNext, handleBack, elevatorId, step3, setStep3}} />
          );
        case 3:
          return (
            <FacialCarPlatePanelControllerBox {...{handleNext, handleBack, elevatorId, step4, setStep4}} />
          );
      }
    };

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);
    
    const { id } = useParams();
    const isEditing = id;

    return(
    <>
        <h1 className="page-header">
                <NavigationButton />
                {!isEditing && 'Create '}Elevator Model {isEditing && ` - ${id}`}
        </h1>
        
        <Row style={{backgroundColor:"#F5F5F5", margin: "0px"}}>
                <Col md={12} style={{marginTop:"30px"}}>
                    <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                        {steps.map((label) => {
                            return (
                            <Step key={label}>
                                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                            </Step>
                            );
                        })}
                    </Stepper>
                  
                </Col>    
                <hr />
                <Col md={12}>
                  {getStepContent(activeStep)}
                </Col>
        </Row>
        
    </>)
} 

export default CreateorUpdateModel;