import React, { useEffect, forwardRef, useState } from "react";
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Config, ConfigEnum } from 'util/Config';
import ElevatorModelDao from "data/ElevatorModelDao";
import { useForm, useFieldArray } from "react-hook-form";
import Notiflix , { Report, Loading } from "notiflix";
import NavigationButton from 'components/buttons/NavigationButton';
import { ApiKey, HairlineStainlessSteel, PowderCoated, SprayPaint, Glass,
         Mirror, BareFinishes, Floor, Material, YesOrNo, Galvanized } from "util/Constant";
import CustomInput from "components/form/CustomInput";
import CustomSelect from "components/form/CustomSelect";
import Checkbox from "components/form/Checkbox";
import CustomCheckboxList from "components/form/CustomCheckboxList";
import { isEmpty } from "lodash";

const FacialCarPlatePanelControllerBox = (props) =>{
    const { register, control, handleSubmit, reset, errors, watch, successCallback } = useForm();
    
    const history = useHistory();
    const { id } = useParams();
    const isEditing = id;
    const { state } = useLocation();

    const { handleNext, handleBack, elevatorId, step4, setStep4 } = props;
    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);
    
    const onSubmit = (data) => {
        Notiflix.Confirm.show(
            'Confirm to next step',
            'Do you want to continue?',
            'Yes',
            'No',
            async () => {
                let dao = new ElevatorModelDao();
                if (isEditing) {
                    data = {
                        ...data,
                        id: state.details.id,
                    }
                    await dao.updateElevatorModelStep4(data, token).then(responseJson => {
                        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                            Report.success('Success', 'Successfully updated model step 4.', 'Okay', () => history.goBack());
                        } else {
                            Report.failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                        }
                    })
                    }else {
                        data = {
                            ...data,
                            id: elevatorId,
                        }  
                        await dao.createElevatorModelStep4(data, token).then((responseJson) => {
                            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                              let model = responseJson[ApiKey._API_DATA_KEY];
                              Notiflix.Report.init({ plainText: false });
                              Notiflix.Report.success(
                                "Success",
                                `Successfully created model step 4.`,
                                "Okay",
                                handleNext()
                              );setStep4(data);history.goBack();
                            } else {
                              Notiflix.Report.failure(
                                "Error",
                                responseJson[ApiKey._API_MESSAGE_KEY]
                              );
                            }
                        });
                    }
            }
        )
    }
    useEffect(() => {
        if (state?.details) {
          console.log(state)
          reset(state?.details);
        }

      document.title = `NECS | ${isEditing ? 'Update' : 'Create'} Elevator Model`;
    }, [reset, isEditing, state])

    return(
    <>
    <form id="elevatorModelStep4" onSubmit={handleSubmit(onSubmit)}>
    <Row style={{backgroundColor:"#F5F5F5", margin: "10px"}}>
        

        <Col md={12}>
            <br />
            <button type="button" className='btn btn-default d-flex-center' style={{color:"#f64e60" , borderColor:"#f64e60", justifyContent:"center", margin:"auto", width:"100%"}} onClick={handleBack}>Back</button>
            <br />
            {isEmpty(state?.details?.deleted_at) && (<button type="submit" className="btn btn-themed btn-block">Continue</button>)}
            {(state?.details?.deleted_at) && (<button className="btn btn-themed btn-block" onClick={handleNext}>Next</button>)}
            <br />
        </Col>
      
    </Row></form>
    </>)
} 

export default FacialCarPlatePanelControllerBox;