const _DEV_HOST = "http://localhost:8000";
const _SIT_HOST = "http://nicheapi.gzit.com.my";
const _PROD_HOST = "https://api.niche123.net";

/// <summary>
/// Author : Robin
/// Host for different Environment
/// </summary>
export class HostKey {
  static getApiHost = () => {
    switch (process.env.REACT_APP_ENV) {
      case "dev":
        return _DEV_HOST;

      case "sit":
        return _SIT_HOST;

      case "prod":
        return _PROD_HOST;

      default:
        return !process.env.NODE_ENV || process.env.NODE_ENV === "development"
          ? _DEV_HOST
          : _SIT_HOST;
    }
  };
}

export const _IMAGE_URL = `${HostKey.getApiHost()}`;

/// <summary>
/// Author : -
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
  static _API_SUCCESS_KEY = "success";
  static _API_MESSAGE_KEY = "message";
  static _API_DATA_KEY = "data";
  static _API_TOTAL_KEY = "totalCount";
  static _API_CONTENT_TYPE = "Content-Type";
  static _API_ACCEPT = "Accept";
  static _API_APPLICATION_JSON = "application/json";
  static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
  static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
  static _API_FORM_DATA = "multipart/form-data";
  static _API_POST = "POST";
  static _API_GET = "GET";
  static _API_DELETE = "DELETE";
  static _API_PUT = "PUT";
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export class ApiUrl {
  static _API_LOGIN = "/auth/login";
  static _API_LOGOUT = "/auth/logout";
  static _API_VALIDATE_LOGIN = "/auth/user";

  static _API_CUSTOMER_LIST = "/customer/list";
  static _API_CREATE_CUSTOMER = "/customer/create";
  static _API_EDIT_CUSTOMER = "/customer/update";
  static _API_DELETE_CUSTOMER = "/customer/delete";
  static _API_GET_CUSTOMER = "/customer/get";
  static _API_UPDATE_CUSTOMER_PERSONAL = "/customer/updatePersonalInfo";
  static _API_UPDATE_CUSTOMER_CONTACT = "/customer/updateContactInfo";
  static _API_UPDATE_CUSTOMER_LOCATION = "/customer/updateLocationInfo";
  static _API_UPDATE_CUSTOMER_OTHER = "/customer/updateOtherInfo";
  static _API_DEACTIVATE_CUSTOMER = "/customer/deactivate";

  static _API_CREATE_ENQUIRY = "/enquiry/create";
  static _API_ENQUIRY_LIST = "/enquiry/list";
  static _API_UPDATE_ENQUIRY = "/enquiry/update";
  static _API_DELETE_ENQUIRY = "/enquiry/delete";
  static _API_UPDATE_ENQUIRY_STATUS = "/enquiry/update/status";
  static _API_ENQUIRY_SITES_NO_QUOTATION = "/enquiry/sites";
  static _API_GET_ENQUIRY = "/enquiry/get";
  static _API_DOWNLOAD_FILES = "/enquiry/download";

  static _API_QUOTATION_LIST = "/quotation/list";
  static _API_CREATE_QUOTATION = "/quotation/create";
  static _API_UPDATE_QUOTATION = "/quotation/update";
  static _API_DELETE_QUOTATION = "/quotation/delete";
  static _API_UPDATE_QUOTATION_STATUS = "/quotation/update/status";

  static _API_ELEVATOR_MODELS = "/elevator-models";
  static _API_UPDATE_ELEVATOR_MODELS = "/elevator-models/update";
  static _API_CREATE_ELEVATOR_MODELS = "/elevator-models/create";
  static _API_MODIFY_ELEVATOR_PART = "/elevator-models/modify-elevator-part";
  //static _API_CREATE_ELEVATOR_PART = "/elevator-models/create-elevator-part";
  static _API_DELETE_ELEVATOR_PART = "/elevator-models/delete-elevator-part";

  static _API_CONTRACT = "/contract";
  static _API_UPDATE_CONTRACT_STATUS = "/contract/update/status";
  static _API_GET_CONTRACT_SITES = "/contract/sites";
  static _API_GET_SITE_CONTRACTS = "/contract/site/contracts";
  static _API_UPLOAD_SIGNED_DOCUMENT = "/contract/uploadSignedDocument";
  static _API_EMPTY_CONTRACT_QUOTATION_LIST = "/quotation/emptyContract";

  static _API_GET_DEPARTMENT = "/department/list";
  static _API_GET_POSITION = "/position/list";
  static _API_GET_NATURE_OF_BUSINESS = "/natureofbusiness/list";
  static _API_GET_BLACKLIST = "/blacklist/list";

  static _API_GET_WAREHOUSE = "/warehouse/list";
  static _API_WAREHOUSE_CREATE = "/warehouse/post";
  static _API_WAREHOUSE_UPDATE = "/warehouse/update";
  static _API_WAREHOUSE_DELETE = "/warehouse/delete";

  static _API_ELEVATOR_MODELS = "/elevator-models";
  static _API_NUMBER_GENERATIONS = "/settings/number-generation";

  static _API_GET_CUSTOMER_LOGS = "/activityLogs/customerlog";
}

/// <summary>
/// Author : -
/// Url to navigate to pages
/// </summary>
export class WebUrl {
  static _LOGIN = "/login";
  static _DASHBOARD = "/dashboard";
  static _CUSTOMER = "/customer";
  static _EDIT_CUSTOMER = "/customer-add-edit";
  static _VIEW_CUSTOMER = "/customer-detail/:id";
  static _ENQUIRY = "/enquiry";
  static _VIEW_ENQUIRY = "/viewenquiry/:id";
  static _VIEW_QUOTATION = "/viewquotation/:id";
  static _QUOTATION = "/quotation";
  static _QUOTATION_CREATE_OR_UPDATE = "/quotation/:id";
  static _CONTRACT = "/contract";
  static _CONTRACT_CREATE_OR_UPDATE = "/contract/:id";
  static _WAREHOUSE = "/management/warehouses";
  static _SETTINGS = "/settings";
  static _ELEVATOR_MODELS = "/management/elevator-model";
  static _ELEVATOR_MODELS_UPDATE = "/management/elevator-model/update/:id";
  static _ELEVATOR_MODELS_CREATE = "/management/elevator-model/create";
}

/// <summary>
/// Author : -
/// </summary>
export class SessionKey {
  static _LANGUAGE = "language";
}

/// <summary>
/// Author : -
/// </summary>
export class Language {
  static _ENGLISH_GB = "en-GB";
  static _CHINESE_HK = "zh-HK";
  static _CHINESE_CN = "zh-CN";
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export class Status {
  static _DELETED = -2;
  static _REJECTED = -1;
  static _INACTIVE = 0;
  static _ACTIVE = 1;
  static _PENDING = 2;
  static _EXPIRED = 3;
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const BtnTypes = {
  ADD: "add",
  DELETE: "delete",
  VIEW: "view",
  EDIT: "edit",
  MISC: "misc",
};

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const InputTypes = {
  INPUT: "input",
  SELECT: "select",
  RADIO: "radio",
  PHONE: "phone",
  TEXTAREA: "textarea",
  ATTACHMENT: "attachment",
  DATEPICKER: "datepicker",
  CHECKBOX: "checkbox",
  FILEUPLOAD: "fileupload",
};

/// <summary>
/// Author: Lewis
/// </summary>
export const SelectField = {
  _PHONE_TYPES: [
    { label: "Mobile", value: "Mobile" },
    { label: "Home", value: "Home" },
    { label: "Work", value: "Work" },
    { label: "Fax", value: "Fax" },
    { label: "Other", value: "Other" },
  ],

  _EMAIL_TYPES: [
    { label: "Personal", value: "Personal" },
    { label: "Work", value: "Work" },
    { label: "Other", value: "Other" },
  ],

  _ADDRESS_TYPES: [
    { label: "Home", value: "Home" },
    { label: "Work", value: "Work" },
    { label: "Other", value: "Other" },
  ],

  _STATE_OPTIONS: [
    { label: "Johor", value: "Johor" },
    { label: "Kedah", value: "Kedah" },
    { label: "Kelantan", value: "Kelantan" },
    { label: "Malacca", value: "Malacca" },
    { label: "Negeri Sembilan", value: "Negeri Sembilan" },
    { label: "Pahang", value: "Pahang" },
    { label: "Penang", value: "Penang" },
    { label: "Perak", value: "Perak" },
    { label: "Perlis", value: "Perlis" },
    { label: "Sabah", value: "Sabah" },
    { label: "Sarawak", value: "Sarawak" },
    { label: "Selangor", value: "Selangor" },
    { label: "Terengganu", value: "Terengganu" },
  ],

  _COUNTRY_OPTIONS: [{ label: "Malaysia", value: "Malaysia" }],

  _GENDER_OPTIONS: [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ],

  STATUS_OPTIONS: [
    { label: "Pending", value: 0 },
    { label: "Rejected", value: 1 },
    { label: "Approved", value: 2 },
  ],

  _BUILDING_STATUS: [
    { label: "Planning Stage", value: 1 },
    { label: "Under Construction", value: 2 },
    { label: "Under Renovation", value: 3 },
    { label: "Completed", value: 4 },
    { label: "Unknown", value: 0 },
  ],

  _BUILDING_OCCUPANCY: [
    { label: "Vacant", value: -1 },
    { label: "Occupied", value: 1 },
    { label: "Not Applicable", value: 0 },
  ],

  _SUPPORT_DISTANCE: [
    { label: "Remote Area", value: 0 },
    { label: "Out of Support Range", value: 1 },
    { label: "Within Support Range", value: 2 },
    { label: "Unknown", value: 3 },
  ],

  _JKKP_REQUIRED: [
    { label: "JKKP Required", value: 0 },
    { label: "JKKP Not Required", value: 1 },
  ],

  _URGENCY: [
    { label: "Low Urgency", value: 0 },
    { label: "Medium Urgency", value: 1 },
    { label: "High Urgency", value: 1 },
  ],

  _TYPES_OF_ACTIONS: [
    { label: "Supply Only", value: 0 },
    { label: "Supply and Install", value: 1 },
    { label: "Dismantle Only", value: 2 },
    { label: "Dismantle, Supply and Install", value: 3 },
  ],

  _PRODUCT_INVOLVED: [
    "Home Lift",
    "OKU Lift",
    "Dumbwaiter",
    "Good Hoist",
    "Others",
  ],

  _BLACKLISTS: [
    { label: "Neutral", value: "Neutral" },
    { label: "Payment", value: "Payment" },
    { label: "Lost Contract", value: "Lost Contract" },
    { label: "Criminal", value: "Criminal" },
    { label: "Deceased", value: "Deceased" },
  ],

  _DESIGNATION: [
    { label: "Mr.", value: "Mr." },
    { label: "Ms. ", value: "Ms." },
    { label: "Mrs.", value: "Mrs." },
    { label: "Dr.", value: "Dr." },
    { label: "Dato", value: "Dato" },
    { label: "Datin", value: "Datin" },
    { label: "Tan Sri", value: "Tan Sri" },
    { label: "YB", value: "YB" },
    { label: "Tengku", value: "Tengku" },
  ],
};

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const Colors = {
  THEME: "#ff0066",
  OFF_THEME: "#ff0066",
  WHITE: "#FFF",
  RED: "#f64e60",
  GRAY: "#dfe1e4",
  BLACK: "#000000",
};

export const ProductType = [
  {label: "Home Lift", value: "Home Lift"},
  {label: "OKU Lift", value: "OKU Lift"},
  {label: "DumbWaiter", value: "DumbWaiter"},
  {label: "Goods Hoist", value: "Goods Hoist"},
  {label: "Scissor Lift", value: "Scissor Lift"},
  {label: "Mini Passenger Lift", value: "Mini Passenger Lift"},
  {label: "Nano Lift", value: "Nano Lift"},
  {label: "Others", value: "Others"},
];
export const ModelType = [
  {label: "ARLA300", value: "ARLA300"},
  {label: "ARLA435", value: "ARLA435"},
  {label: "ARLH300", value: "ARLH300"},
  {label: "ARLH435", value: "ARLH435"},
  {label: "VLP300", value: "VLP300"},
  {label: "VLP450", value: "VLP450"},
  {label: "DW50", value: "DW50"},
  {label: "DW100", value: "DW100"},
  {label: "DW150", value: "DW150"},
  {label: "DW200", value: "DW200"},
  {label: "DW300", value: "DW300"},
  {label: "G500", value: "G500"},
  {label: "G1000", value: "G1000"},
  {label: "G1500", value: "G1500"},
  {label: "G2000", value: "G2000"},
  {label: "ARLB300", value: "ARLB300"},
  {label: "ARLB435", value: "ARLB435"},
  {label: "SL1000", value: "SL1000"},
  {label: "SL2500", value: "SL2500"},
  {label: "SL3000", value: "SL3000"},
  {label: "Others", value: "Others"},
];
export const CarEnclosure = [
  {label: "Fully Enclosed", value: "Fully Enclosed"},
  {label: "Half Panel Height", value: "Half Panel Height"},
  {label: "Platform Only", value: "Platform Only"},
  {label: "Others", value: "Others"},
];
export const CarCeilingType = [
  {label: "Direct", value: "Direct"},
  {label: "Suspended", value: "Suspended"},
  {label: "Recess", value: "Recess"},
  {label: "Not Required", value: "Not Required"},
];
export const Lighting = [
  {label: "Round LED Downlight", value: "Round LED Downlight"},
  {label: "Square Downlight", value: "Square Downlight"},
  {label: "LED Strips with V-Slot Aluminum Casing", value: "LED Strips with V-Slot Aluminum Casing"},
  {label: "Fluorescent Light", value: "Fluorescent Light"},
  {label: "Not Required", value: "Not Required"},
];
export const CarHandrail = [
  {label: "Side A", value: "Side A"},
  {label: "Side B", value: "Side B"},
  {label: "Side C", value: "Side C"},
  {label: "Not Required", value: "Not Required"},
];

export const DoorEntranceType = [
  {label: "Same Side", value: "Same Side"},
  {label: "Through", value: "Through"},
  {label: "Adjacent (D-A)", value: "Adjacent (D-A)"},
  {label: "Adjacent (D-C)", value: "Adjacent (D-C)"},
  {label: "Others", value: "Others"},
];
export const DoorOrientation = [
  {label: "Left Hand", value: "Left Hand"},
  {label: "Right Hand", value: "Right Hand"},
  {label: "Center Opening", value: "Center Opening"},
  {label: "Others", value: "Others"},
];
export const CarDoorMechanism = [
  {label: "Automatic Sliding Door (2S-2P)", value: "Automatic Sliding Door (2S-2P)"},
  {label: "Automatic Sliding Door (3S-3P)", value: "Automatic Sliding Door (3S-3P)"},
  {label: "Automatic Sliding Door (2S-3P)", value: "Automatic Sliding Door (2S-3P)"},
  {label: "Automatic Roller Shutter", value: "Automatic Roller Shutter"},
  {label: "Door Detector", value: "Door Detector"},
  {label: "Folding Door", value: "Folding Door"},
  {label: "Manual Roller Shutter", value: "Manual Roller Shutter"},
  {label: "Vertical Bi-Parting (2P)", value: "Vertical Bi-Parting (2P)"},
  {label: "Vertical Up-Sliding (2P)", value: "Vertical Up-Sliding (2P)"},
  {label: "Vertical Up-Sliding (3P)", value: "Vertical Up-Sliding (3P)"},
  {label: "Collapsible Gate (Shutter)", value: "Collapsible Gate (Shutter)"},
  {label: "Collapsible Gate (No Shutter)", value: "Collapsible Gate (No Shutter)"},
  {label: "Telescopic Door", value: "Telescopic Door"},
  {label: "Others", value: "Others"},
];
export const LandingDoorMechanism = [
  {label: "Automatic Sliding Door (2S-2P)", value: "Automatic Sliding Door (2S-2P)"},
  {label: "Automatic Sliding Door (3S-3P)", value: "Automatic Sliding Door (3S-3P)"},
  {label: "Automatic Sliding Door (2S-3P)", value: "Automatic Sliding Door (2S-3P)"},
  {label: "Automatic Swing Door (1P)", value: "Automatic Swing Door (1P)"},
  {label: "Automatic Swing Door (2P)", value: "Automatic Swing Door (2P)"},
  {label: "Automatic Roller Shutter", value: "Automatic Roller Shutter"},
  {label: "Manual Swing Door (1P)", value: "Manual Swing Door (1P)"},
  {label: "Manual Roller Shutter", value: "Manual Roller Shutter"},
  {label: "Vertical Bi-Parting (2P)", value: "Vertical Bi-Parting (2P)"},
  {label: "Vertical Up-Sliding (2P)", value: "Vertical Up-Sliding (2P)"},
  {label: "Vertical Up-Sliding (3P)", value: "Vertical Up-Sliding (3P)"},
  {label: "Collapsible Gate (Shutter)", value: "Collapsible Gate (Shutter)"},
  {label: "Collapsible Gate (No Shutter)", value: "Collapsible Gate (No Shutter)"},
  {label: "Telescopic Door", value: "Telescopic Door"},
  {label: "Others", value: "Others"},
];
export const LandingDoorFrameType = [
  {label: "Landing Wide Jamb - 165 x (L:25 x T: x R:25)", value: "Landing Wide Jamb - 165 x (L:25 x T: x R:25)"},
  {label: "Landing Wide Jamb - 165 x (L:25 x T:100 x R:25)", value: "Landing Wide Jamb - 165 x (L:25 x T:100 x R:25)"},
  {label: "Landing Wide Jamb - 165 x (L:100 x T:25 x R:100)", value: "Landing Wide Jamb - 165 x (L:100 x T:25 x R:100)"},
  {label: "Landing Wide Jamb - 165 x (L:25 x T:25 x R:100)", value: "Landing Wide Jamb - 165 x (L:25 x T:25 x R:100)"},
  {label: "Landing Wide Jamb - 165 x (L:100 x T:25 x R:25)", value: "Landing Wide Jamb - 165 x (L:100 x T:25 x R:25)"},
  {label: "Landing Wide Jamb - 165 x (L:25 x T:100 x R:25)", value: "Landing Wide Jamb - 165 x (L:25 x T:100 x R:25)"},
  {label: "Landing Wide Jamb - 165 x (L:100 x T:100 x R:25)", value: "Landing Wide Jamb - 165 x (L:100 x T:100 x R:25)"},
  {label: "Standard Glass Shaft Enclosure Built-In", value: "Standard Glass Shaft Enclosure Built-In"},
  {label: "DW Table Type", value: "DW Table Type"},
  {label: "DW Floor Type", value: "DW Floor Type"},
  {label: "GH Narrow - 120 x (L:60 x T:60 x R:60)", value: "GH Narrow - 120 x (L:60 x T:60 x R:60)"},
  {label: "Non-Standard Full Wall Frame", value: "Non-Standard Full Wall Frame"},
  {label: "Non-Standard Narrow Jamb", value: "Non-Standard Narrow Jamb"},
  {label: "Non-Standard Spray Type", value: "Non-Standard Spray Type"},
  {label: "Non-Standard with Transom", value: "Non-Standard with Transom"},
  {label: "Non-Standard with Architrave", value: "Non-Standard with Architrave"},
  {label: "Others", value: "Others"},
];
export const LandingDoorStillPosition = [
  {label: "Inside Lift Shaft", value: "Inside Lift Shaft"},
  {label: "On Top of Floor Slab", value: "On Top of Floor Slab"},
  {label: "Others", value: "Others"},
];

export const required = [
  {id: 0, label: "Required",  value: true},
];
export const YesOrNo = [
  {id: 0, label: "No",  value: true},
];

export const CarFan = [
  "Not Required",
  "Axial Fan",
  "Cross Flow Fan"
];
export const HairlineStainlessSteel = [
  {label: "Silver (NE-SS01) - 304", value: "Silver (NE-SS01) - 304"},
  {label: "Silver (NE-SS02) - 316", value: "Silver (NE-SS02) - 316"},
  {label: "Nickel Silver (AT-NS01)", value: "Nickel Silver (AT-NS01)"},
  {label: "Champagne (AT-CG01)", value: "Champagne (AT-CG01)"},
  {label: "Gold (AT-YG01)", value: "Gold (AT-YG01)"},
  {label: "Rose Gold (AT-RG01)", value: "Rose Gold (AT-RG01)"},
  {label: "Bronze Gold (AT-BG01)", value: "Bronze Gold (AT-BG01)"},
  {label: "Bronze Red (AT-BR01)", value: "Bronze Red (AT-BR01)"},
  {label: "Coffee (AT-CF01)", value: "Coffee (AT-CF01)"},
  {label: "Black Titanium (AT-BT01)", value: "Black Titanium (AT-BT01)"},
  {label: "Others", value: "Others"},
];
export const PowderCoated = [
  {label: "White", value: "White"},
  {label: "Black", value: "Black"},
  {label: "Others", value: "Others"},
];
export const SprayPaint = [
  {label: "White", value: "White"},
  {label: "Silver", value: "Silver"},
  {label: "Black", value: "Black"},
  {label: "M.I.O", value: "M.I.O"},
  {label: "Others", value: "Others"},
];
export const Glass = [
  {label: "Clear Laminated Safety Glass", value: "Clear Laminated Safety Glass"},
  {label: "Clear Tempered and Laminated Safety Glass", value: "Clear Tempered and Laminated Safety Glass"},
];
export const Mirror = [
  {label: "Laminated Safety Mirror", value: "Laminated Safety Mirror"},
  {label: "Mirror Stainless Steel", value: "Mirror Stainless Steel"},
  {label: "Bronze Mirror", value: "Bronze Mirror"},
];
export const BareFinishes = [
  {label: "Electro Galvanised", value: "Electro Galvanised"},
  {label: "Plywood", value: "Plywood"},
];
export const Floor = [
  {label: "Others", value: "Others"},
  {label: "Niche", value: "Niche"},
];
export const Material = [
  {label: "Vinyl Sheets", value: "Vinyl Sheets"},
  {label: "Rubber Studded Tile", value: "Rubber Studded Tile"},
  {label: "Chequered Plate", value: "Chequered Plate"},
  {label: "Others", value: "Others"},
];
export const Galvanized = [
  {label: "Galvanized Iron", value: "Galvanized Iron"},
  {label: "Electro Galvanized", value: "Electro Galvanized"},
];

export const WallPanelSide = [
  {label: "Side A : Left", value: "Side A : Left"},
  {label: "Side B : Rear", value: "Side B : Rear"},
  {label: "Side C : Right", value: "Side C : Right"},
];

export const DoorPanelTypes = [
  {label: "Solid Door", value: "Solid Door"},
  {label: "Big Vision Door", value: "Big Vision Door"},
  {label: "Frameless Door", value: "Frameless Door"},
  {label: "Others", value: "Others"},
];

export const SevenTypesOfMaterial = [
  {label: "Hairline Stainless Steel", value: "Hairline Stainless Steel"},
  {label: "Power Coated", value: "Power Coated"},
  {label: "Spray Paint", value: "Spray Paint"},
  {label: "Glass", value: "Glass"},
  {label: "Mirror", value: "Mirror"},
  {label: "Bare Finishes", value: "Bare Finishes"},
  {label: "Others", value: "Others"},
];

export const FourTypesOfMaterial = [
  {label: "Hairline Stainless Steel", value: "Hairline Stainless Steel"},
  {label: "Power Coated", value: "Power Coated"},
  {label: "Spray Paint", value: "Spray Paint"},
  {label: "Others", value: "Others"},
];

export const ThreeTypesOfMaterial = [
  {label: "Hairline Stainless Steel", value: "Hairline Stainless Steel"},
  {label: "Device's Default", value: "Device's Default"},
  {label: "Others", value: "Others"},
];

export const LandingDoorFrameMaterial = [
  {label: "Hairline Stainless Steel", value: "Hairline Stainless Steel"},
  {label: "Spray Paint", value: "Spray Paint"},
  {label: "Bare Finishes", value: "Bare Finishes"},
  {label: "Others", value: "Others"},
];

export const LiftShaftMaterial = [
  {label: "Galvanized", value: "Galvanized"},
  {label: "Hairline Stainless Steel", value: "Hairline Stainless Steel"},
  {label: "Power Coated", value: "Power Coated"},
  {label: "Spray Paint", value: "Spray Paint"},
  {label: "Others", value: "Others"},
];
