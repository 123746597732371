import React, { useEffect, forwardRef, useState } from "react";
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Config, ConfigEnum } from 'util/Config';
import ElevatorModelDao from "data/ElevatorModelDao";
import { useForm, useFieldArray } from "react-hook-form";
import Notiflix , { Report, Loading } from "notiflix";
import NavigationButton from 'components/buttons/NavigationButton';
import { ProductType, ModelType, CarEnclosure, CarCeilingType,  Lighting,  CarHandrail, 
         DoorEntranceType,  DoorOrientation,  CarDoorMechanism, LandingDoorMechanism, 
         LandingDoorFrameType, LandingDoorStillPosition,  required, CarFan, ApiKey } from "util/Constant";
import CustomInput from "components/form/CustomInput";
import CustomSelect from "components/form/CustomSelect";
import Checkbox from "components/form/Checkbox";
import CustomCheckboxList from "components/form/CustomCheckboxList";
import { tryCatch } from 'util/Utility';
import { isEmpty } from "lodash";

//backup
const GeneralInfo = (props) =>{
    const { register, control, handleSubmit, reset, errors, watch, successCallback } = useForm();
    
    const history = useHistory();
    const { id } = useParams();
    const isEditing = id;
    const { state } = useLocation();

    const { handleNext, handleBack, elevatorId, setElevatorId, generalInfo, setGeneralInfo } = props;
    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);
    
    const onSubmit = (data) => {
        Notiflix.Confirm.show(
          'Confirm to next step',
          'Do you want to continue?',
          'Yes',
          'No',
          async () => {
            let dao = new ElevatorModelDao();
            if (isEditing) {
              data = {
                  ...data,
                  id: state.details.id,
                  jkkpRequirement: watch("jkkpRequirement") === 'true' ? true : false,
                  carKickplate: watch("carKickplate") === 'true' ? true : false,
                  fireRated: watch("fireRated") === 'true' ? true : false,
              }
              await dao.updateElevatorModel(data, token).then(responseJson => {
                  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                      Report.success('Success', 'Successfully updated model step 1.', 'Okay', handleNext());
                      setGeneralInfo(data);
                  } else {
                      Report.failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                  }
              })
            }else {
              data = {
                ...data,
                jkkpRequirement: watch("jkkpRequirement") === 'true' ? true : false,
                carKickplate: watch("carKickplate") === 'true' ? true : false,
                fireRated: watch("fireRated") === 'true' ? true : false,
              }
              await dao.createElevatorModel(data, token).then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                  let id = responseJson[ApiKey._API_DATA_KEY];
                  setElevatorId(id);
                  Notiflix.Report.init({ plainText: false });
                  Notiflix.Report.success(
                    "Success",
                    `Successfully created model step 1.`,
                    "Okay",
                    handleNext()
                  );setGeneralInfo(data);
                } else {
                  Notiflix.Report.failure(
                    "Error",
                    responseJson[ApiKey._API_MESSAGE_KEY]
                  );
                }
                });
            }
          }
        )
    }

    useEffect(() => {
      if (state?.details) {
          console.log(state)
          reset(state?.details);
      }

      document.title = `NECS | ${isEditing ? 'Update' : 'Create'} Elevator Model`;
    }, [reset, isEditing, state])

    return(
    <>
        <form id="elevatorModelGeneralInfo" onSubmit={handleSubmit(onSubmit)}>

          <Row style={{backgroundColor:"#F5F5F5", margin: "10px"}}>
            <Col md={12}>
            <form id="generalInfo">
            <Row style={{backgroundColor:"#F5F5F5", margin: "10px"}}>
                <Col md={10}>
                  <h1 className="page-subheader" style={{borderBottom: "2px solid #e4e6e8"}}>
                      General Info
                  </h1>
                </Col>
                <Col md={2}>
                <button type="reset" className='btn btn-default d-flex-center' style={{color:"#f64e60" , borderColor:"#f64e60", justifyContent:"center", margin:"auto", width:"100%"}}>Reset Fields &nbsp; <i className="fas fa-undo"></i></button>
                </Col>
                <Col md={6}>
                    <label className="custom-input-label">Product Type</label>
                        <CustomSelect
                          control={control}
                          name="productType"
                          label="Product Type"
                          placeholder="Choose the product type"
                          options={ProductType}
                          defaultValue={state?.details ? state.details.product_type : generalInfo?.productType}
                          rules={{
                              required: true,
                          }}
                        /> 
                </Col>
                <Col md={6}>
                    <label className="custom-input-label">Model Type</label>
                        <CustomSelect
                          control={control}
                          name="modelType"
                          label="Model Type"
                          placeholder="Choose the model type"
                          options={ModelType}
                          defaultValue={state?.details ? state.details.model_type : generalInfo?.modelType}
                          rules={{
                              required: true,
                          }}
                        /> 
                </Col>
                <Col md={12}><br /></Col>
                <Col md={3}>
                    <label className="custom-input-label">Rated Load <i>(kg)</i></label>
                        <CustomInput
                          {...register("ratedLoad", {
                            required: "Rated Load is required",
                          })}
                          name="ratedLoad"
                          label="Rated Load (kg)"
                          defaultValue={state?.details ? state.details.rated_load : generalInfo?.ratedLoad}
                        />
                </Col>
                <Col md={3}>
                    <label className="custom-input-label">Rated Speed <i>(mpm)</i></label>
                        <CustomInput
                          {...register("ratedSpeed", {
                            required: "Rated Speed is required",
                          })}
                          name="ratedSpeed"
                          label="Rated Speed (mpm)"
                          defaultValue={state?.details ? state.details.rated_speed : generalInfo?.ratedSpeed}
                        />
                </Col>
                <Col md={3}>
                    <label className="custom-input-label">Number of Stops</label>
                        <CustomInput
                          {...register("numberOfStops", {
                            required: "Number of Stops is required",
                          })}
                          name="numberOfStops"
                          label="Number of Stops"
                          defaultValue={state?.details ? state.details.number_of_stops : generalInfo?.numberOfStops}
                        />
                </Col>
                <Col md={3}>
                    <label className="custom-input-label">Car JKKP Requirement</label>
                        <Checkbox 
                          {...register("jkkpRequirement", {
                            required: false,
                          })}
                            name="jkkpRequirement"
                            options={required}
                            defaultValue={state?.details ? state.details.jkkp_requirement == 1? true : false : generalInfo?.jkkpRequirement === true ? true : false}
                        />
                </Col>
                </Row>
            </form>
            </Col>
            <Col md={12}>
            <form id="liftCarInfo">
            <Row style={{backgroundColor:"#F5F5F5", margin: "10px"}}>
                <Col md={10}>
                  <h1 className="page-subheader" style={{borderBottom: "2px solid #e4e6e8"}}>
                      Lift Car Info
                  </h1>
                </Col>
                <Col md={2}>
                <button type="reset" className='btn btn-default d-flex-center' style={{color:"#f64e60" , borderColor:"#f64e60", justifyContent:"center", margin:"auto", width:"100%"}}>Reset Fields &nbsp; <i className="fas fa-undo"></i></button>
                </Col>
                <Col md={12}>
                    <label className="custom-input-label">Car Enclosure</label>
                        <CustomSelect
                          control={control}
                          name="carEnclosure"
                          label="Car Enclosure"
                          placeholder="Choose the car enclosure"
                          options={CarEnclosure}
                          rules={{
                              required: true,
                          }}
                          defaultValue={state?.details ? state.details.car_enclosure : generalInfo?.carEnclosure}
                        /> 
                </Col>
                <Col md={12}><br /></Col>
                <Col md={4}>
                    <label className="custom-input-label">Car Width <i>(mm)</i></label>
                        <CustomInput
                          {...register("carWidth", {
                            required: "Car Width is required",
                          })}
                          name="carWidth"
                          label="Car Width (mm)"
                          defaultValue={state?.details ? state.details.car_width : generalInfo?.carWidth}
                        />
                </Col>
                <Col md={4}>
                    <label className="custom-input-label">Car Depth <i>(mm)</i></label>
                        <CustomInput
                          {...register("carDepth", {
                            required: "Car Depth is required",
                          })}
                          name="carDepth"
                          label="Car Depth (mm)"
                          defaultValue={state?.details ? state.details.car_depth : generalInfo?.carDepth}
                        />
                </Col>
                <Col md={4}>
                    <label className="custom-input-label">Car Height <i>(mm)</i></label>
                        <CustomInput
                          {...register("carHeight", {
                            required: "Car Height is required",
                          })}
                          name="carHeight"
                          label="Car Height (mm)"
                          defaultValue={state?.details ? state.details.car_height : generalInfo?.carHeight}
                        />
                </Col>
                <Col md={12}><br /></Col>
                <Col md={12}>
                    <label className="custom-input-label">Car Ceiling Type</label>
                        <CustomSelect
                          control={control}
                          name="carCeilingType"
                          label="Car Ceiling Type"
                          placeholder="Choose the car ceiling type"
                          options={CarCeilingType}
                          rules={{
                              required: true,
                          }}
                          defaultValue={state?.details ? state.details.car_ceiling_type : generalInfo?.carCeilingType}
                        /> 
                </Col>

                <Col md={12}><br /></Col>
                <Col md={12}>
                    <label className="custom-input-label">Car Lighting</label>
                        <CustomSelect
                          control={control}
                          name="carLighting"
                          label="Car Lighting"
                          placeholder="Choose the car lighting"
                          options={Lighting}
                          rules={{
                              required: true,
                          }}
                          defaultValue={state?.details ? state.details.car_lighting : generalInfo?.carLighting}
                        /> 
                </Col>

                <Col md={12}><br /></Col>
                <Col md={4}>
                    <label className="custom-input-label">Car Fan</label>
                        <CustomCheckboxList 
                            control={control}
                            name="carFan"
                            label="Choose the type of car fan"
                            options={CarFan}
                            defaultValue={state?.details ? state.details.car_fan : generalInfo?.carFan}
                            placeholder="Choose the product type"
                            {...register("carFan", {
                              required: false,
                            })}
                        />
                </Col>
                <Col md={4}>
                    <label className="custom-input-label">Car Handrail</label>
                        <CustomSelect
                          control={control}
                          name="carHandrail"
                          label="Car Handrail"
                          placeholder="Choose the car handrail"
                          options={CarHandrail}
                          rules={{
                              required: true,
                          }}
                          defaultValue={state?.details ? state.details.car_handrail : generalInfo?.carHandrail}
                        />
                </Col>
                <Col md={4}>
                    <label className="custom-input-label">Car Kickplate</label>
                        <Checkbox 
                          {...register("carKickplate", {
                            required: false,
                          })}
                            name="carKickplate"
                            options={required}
                            defaultValue={state?.details ? state.details.car_kickplate == 1? true : false : generalInfo?.carKickplate === true ? true : false}
                        />
                </Col>

                </Row>
            </form>
            </Col>

            <Col md={12}>
            <form id="liftDoorConfigurations">
            <Row style={{backgroundColor:"#F5F5F5", margin: "10px"}}>
                <Col md={10}>
                  <h1 className="page-subheader" style={{borderBottom: "2px solid #e4e6e8"}}>
                      Lift Door Configurations
                  </h1>
                </Col>
                <Col md={2}>
                <button type="reset" className='btn btn-default d-flex-center' style={{color:"#f64e60" , borderColor:"#f64e60", justifyContent:"center", margin:"auto", width:"100%"}}>Reset Fields &nbsp; <i className="fas fa-undo"></i></button>
                </Col>
                <Col md={6}>
                    <label className="custom-input-label">Door Entrance Width <i>(mm)</i></label>
                        <CustomInput
                          {...register("doorEntranceWidth", {
                            required: "Door Entrance Width is required",
                          })}
                          name="doorEntranceWidth"
                          label="Door Entrance Width (mm)"
                          defaultValue={state?.details ? state.details.door_entrance_width : generalInfo?.doorEntranceWidth}
                        />
                </Col>
                <Col md={6}>
                    <label className="custom-input-label">Door Entrance Height <i>(mm)</i></label>
                        <CustomInput
                          {...register("doorEntranceHeight", {
                            required: "Door Entrance Height is required",
                          })}
                          name="doorEntranceHeight"
                          label="Door Entrance Height (mm)"
                          defaultValue={state?.details ? state.details.door_entrance_height : generalInfo?.doorEntranceHeight}
                        />
                </Col>
                <Col md={12}><br /></Col>
                <Col md={6}>
                    <label className="custom-input-label">Door Entrance Type</label>
                        <CustomSelect
                          control={control}
                          name="doorEntranceType"
                          label="Door Entrance Type"
                          placeholder="Choose the door entrance type"
                          options={DoorEntranceType}
                          rules={{
                              required: true,
                          }}
                          defaultValue={state?.details ? state.details.door_entrance_type : generalInfo?.doorEntranceType}
                        />
                </Col>
                <Col md={6}>
                    <label className="custom-input-label">Door Orientation</label>
                        <CustomSelect
                          control={control}
                          name="doorOrientation"
                          label="Door Orientation"
                          placeholder="Choose the doorOrientation"
                          options={DoorOrientation}
                          rules={{
                              required: true,
                          }}
                          defaultValue={state?.details ? state.details.door_orientation : generalInfo?.doorOrientation}
                        />
                </Col>
                <Col md={12}><br /></Col>
                <Col md={12}>
                    <label className="custom-input-label">Car Door Mechanism</label>
                        <CustomSelect
                          control={control}
                          name="carDoorMechanism"
                          label="Car Door Mechanism"
                          placeholder="Choose the car door mechanism"
                          options={CarDoorMechanism}
                          rules={{
                              required: true,
                          }}
                          defaultValue={state?.details ? state.details.car_door_mechanism : generalInfo?.carDoorMechanism}
                        />
                </Col>
                <Col md={12}><br /></Col>
                <Col md={12}>
                    <label className="custom-input-label">Landing Door Mechanism</label>
                        <CustomSelect
                          control={control}
                          name="landingDoorMechanism"
                          label="Landing Door Mechanism"
                          placeholder="Choose the landing door mechanism"
                          options={LandingDoorMechanism}
                          rules={{
                              required: true,
                          }}
                          defaultValue={state?.details ? state.details.landing_door_mechanism : generalInfo?.landingDoorMechanism}
                        />
                </Col>
                <Col md={12}><br /></Col>
                <Col md={12}>
                    <label className="custom-input-label">Landing Door Frame Type</label>
                        <CustomSelect
                          control={control}
                          name="landingDoorFrameType"
                          label="Landing Door Frame Type"
                          placeholder="Choose the landing door frame type"
                          options={LandingDoorFrameType}
                          rules={{
                              required: true,
                          }}
                          defaultValue={state?.details ? state.details.landing_door_frame_type : generalInfo?.landingDoorFrameType}
                        />
                </Col>
                <Col md={12}><br /></Col>
                <Col md={6}>
                    <label className="custom-input-label">Landing Door Still Position</label>
                        <CustomSelect
                          control={control}
                          name="landingDoorStillPosition"
                          label="Landing Door Still Position"
                          placeholder="Choose the landing door still position"
                          options={LandingDoorStillPosition}
                          rules={{
                              required: true,
                          }}
                          defaultValue={state?.details ? state.details.landing_door_still_position : generalInfo?.landingDoorStillPosition}
                        />
                </Col>
                <Col md={6}>
                    <label className="custom-input-label">Fire Rated</label>
                        <Checkbox 
                            {...register("fireRated", {
                              required: false,
                            })}
                            control={control}
                            name="fireRated"
                            options={required}
                            defaultValue={state?.details ? state.details.fire_rated == 1? true : false : generalInfo?.fireRated === true ? true : false}
                        />
                </Col>
                
                </Row>
            </form>
            </Col>

            <Col md={12}>
                <br /><br />
                <button type="button" className='btn btn-default d-flex-center' style={{color:"#f64e60" , borderColor:"#f64e60", justifyContent:"center", margin:"auto", width:"100%"}} onClick={handleBack}>Back</button>
                <br />
                {isEmpty(state?.details?.deleted_at) && (<button type="submit" className="btn btn-themed btn-block">Continue</button>)}
                {(state?.details?.deleted_at) && (<button className="btn btn-themed btn-block" onClick={handleNext}>Next</button>)}
                <br />
            </Col>

          </Row> 
        </form>
    </>)
} 

export default GeneralInfo;