import React, { useEffect, forwardRef, useState } from "react";
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Config, ConfigEnum } from 'util/Config';
import ElevatorModelDao from "data/ElevatorModelDao";
import { useForm, useFieldArray } from "react-hook-form";
import Notiflix , { Report, Loading } from "notiflix";
import NavigationButton from 'components/buttons/NavigationButton';
import { ApiKey, HairlineStainlessSteel, PowderCoated, SprayPaint, Glass,
         Mirror, BareFinishes, Galvanized, DoorPanelTypes, SevenTypesOfMaterial,
         FourTypesOfMaterial, ThreeTypesOfMaterial, LandingDoorFrameMaterial, LiftShaftMaterial } from "util/Constant";
import CustomInput from "components/form/CustomInput";
import CustomSelect from "components/form/CustomSelect";
import Checkbox from "components/form/Checkbox";
import CustomCheckboxList from "components/form/CustomCheckboxList";
import { isEmpty } from "lodash";

const FrontReturnDoorPanelToeGuard = (props) =>{
    const { register, control, handleSubmit, reset, errors, watch, successCallback } = useForm();
    
    const history = useHistory();
    const { id } = useParams();
    const isEditing = id;
    const { state } = useLocation();

    const { handleNext, handleBack, elevatorId, step3, setStep3 } = props;
    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    const [landingDoorTypeSelected, setLandingDoorTypeSelected] = useState(false);
    const [landingDoorType, setLandingDoorType] = useState(null);
    
    const [shaftGalvanizedSelected, setShaftGalvanizedSelected] = useState(false);
    const [shaftSteelSelected, setShaftSteelSelected] = useState(false);
    const [shaftCoatedSelected, setShaftCoatedSelected] = useState(false);
    const [shaftPaintSelected, setShaftPaintSelected] = useState(false);
    const [shaftOthersSelected, setShaftOthersSelected] = useState(false);

    const [landingFrameSteelSelected, setLandingFrameSteelSelected] = useState(false);
    const [landingFrameCoatedSelected, setLandingFrameCoatedSelected] = useState(false);
    const [landingFramePaintSelected, setLandingFramePaintSelected] = useState(false);
    const [landingFrameFinishesSelected, setLandingFrameFinishesSelected] = useState(false);
    const [landingFrameOthersSelected, setLandingFrameOthersSelected] = useState(false);

    const [landingPanelSteelSelected, setLandingPanelSteelSelected] = useState(false);
    const [landingPanelCoatedSelected, setLandingPanelCoatedSelected] = useState(false);
    const [landingPanelPaintSelected, setLandingPanelPaintSelected] = useState(false);
    const [landingPanelOthersSelected, setLandingPanelOthersSelected] = useState(false);

    const [controllerBoxSteelSelected, setControllerBoxSteelSelected] = useState(false);
    const [controllerBoxCoatedSelected, setControllerBoxCoatedSelected] = useState(false);
    const [controllerBoxPaintSelected, setControllerBoxPaintSelected] = useState(false);
    const [controllerBoxOthersSelected, setControllerBoxOthersSelected] = useState(false);

    const [carOperatingPanelSteelSelected, setCarOperatingPanelSteelSelected] = useState(false);
    const [carOperatingPanelOthersSelected, setCarOperatingPanelOthersSelected] = useState(false);

    const [hallOperatingPanelSteelSelected, setHallOperatingPanelSteelSelected] = useState(false);
    const [hallOperatingPanelOthersSelected, setHallOperatingPanelOthersSelected] = useState(false);

    const handleLandingDoorPanelType = (data) =>{
        if(data == "Solid Door" || data == "Big Vision Door" || data == "Frameless Door" || data == "Others"){
            setLandingDoorType(data);
            setLandingDoorTypeSelected(true);
        }
        else {
            setLandingDoorType(null);
            setLandingDoorTypeSelected(false);
        }
    }
    
    const handleShaft = (data) =>{
        if(data == "Galvanized"){
            setShaftGalvanizedSelected(true);
            setShaftSteelSelected(false);
            setShaftCoatedSelected(false);
            setShaftPaintSelected(false);
            setShaftOthersSelected(false);
        } else if(data == "Hairline Stainless Steel"){
            setShaftSteelSelected(true);
            setShaftGalvanizedSelected(false);
            setShaftCoatedSelected(false);
            setShaftPaintSelected(false);
            setShaftOthersSelected(false);
        } else if (data == "Power Coated"){
            setShaftCoatedSelected(true);
            setShaftGalvanizedSelected(false);
            setShaftSteelSelected(false);
            setShaftPaintSelected(false);
            setShaftOthersSelected(false);
        } else if (data == "Spray Paint"){
            setShaftPaintSelected(true);
            setShaftGalvanizedSelected(false);
            setShaftSteelSelected(false);
            setShaftCoatedSelected(false);
            setShaftOthersSelected(false);
        } else if (data == "Others"){
            setShaftOthersSelected(true);
            setShaftGalvanizedSelected(false);
            setShaftSteelSelected(false);
            setShaftCoatedSelected(false);
            setShaftPaintSelected(false);
        }
    }
    
    const handleLandingFrame = (data) =>{
        if(data == "Hairline Stainless Steel"){
            setLandingFrameSteelSelected(true);
            setLandingFrameCoatedSelected(false);
            setLandingFramePaintSelected(false);
            setLandingFrameFinishesSelected(false);
            setLandingFrameOthersSelected(false);
        } else if (data == "Power Coated"){
            setLandingFrameCoatedSelected(true);
            setLandingFrameSteelSelected(false);
            setLandingFramePaintSelected(false);
            setLandingFrameFinishesSelected(false);
            setLandingFrameOthersSelected(false);
        } else if (data == "Spray Paint"){
            setLandingFramePaintSelected(true);
            setLandingFrameSteelSelected(false);
            setLandingFrameCoatedSelected(false);
            setLandingFrameFinishesSelected(false);
            setLandingFrameOthersSelected(false);
        } else if (data == "Bare Finishes"){
            setLandingFrameFinishesSelected(true);
            setLandingFrameSteelSelected(false);
            setLandingFrameCoatedSelected(false);
            setLandingFramePaintSelected(false);
            setLandingFrameOthersSelected(false);
        } else if (data == "Others"){
            setLandingFrameOthersSelected(true);
            setLandingFrameSteelSelected(false);
            setLandingFrameCoatedSelected(false);
            setLandingFramePaintSelected(false);
            setLandingFrameFinishesSelected(false);
        }
    }
    
    const handleLandingPanel = (data) =>{
        if(data == "Hairline Stainless Steel"){
            setLandingPanelSteelSelected(true);
            setLandingPanelCoatedSelected(false);
            setLandingPanelPaintSelected(false);
            setLandingPanelOthersSelected(false);
        } else if (data == "Power Coated"){
            setLandingPanelCoatedSelected(true);
            setLandingPanelSteelSelected(false);
            setLandingPanelPaintSelected(false);
            setLandingPanelOthersSelected(false);
        } else if (data == "Spray Paint"){
            setLandingPanelPaintSelected(true);
            setLandingPanelSteelSelected(false);
            setLandingPanelCoatedSelected(false);
            setLandingPanelOthersSelected(false);
        } else if (data == "Others"){
            setLandingPanelOthersSelected(true);
            setLandingPanelSteelSelected(false);
            setLandingPanelCoatedSelected(false);
            setLandingPanelPaintSelected(false);
        }
    }
    
    const handleControllerBox = (data) =>{
        if(data == "Hairline Stainless Steel"){
            setControllerBoxSteelSelected(true);
            setControllerBoxCoatedSelected(false);
            setControllerBoxPaintSelected(false);
            setControllerBoxOthersSelected(false);
        } else if (data == "Power Coated"){
            setControllerBoxCoatedSelected(true);
            setControllerBoxSteelSelected(false);
            setControllerBoxPaintSelected(false);
            setControllerBoxOthersSelected(false);
        } else if (data == "Spray Paint"){
            setControllerBoxPaintSelected(true);
            setControllerBoxSteelSelected(false);
            setControllerBoxCoatedSelected(false);
            setControllerBoxOthersSelected(false);
        } else if (data == "Others"){
            setControllerBoxOthersSelected(true);
            setControllerBoxSteelSelected(false);
            setControllerBoxCoatedSelected(false);
            setControllerBoxPaintSelected(false);
        }
    }
    
    const handleCarOperatingPanel = (data) =>{
        if(data == "Hairline Stainless Steel"){
            setCarOperatingPanelSteelSelected(true);
            setCarOperatingPanelOthersSelected(false);
        } else if (data == "Others"){
            setCarOperatingPanelOthersSelected(true);
            setCarOperatingPanelSteelSelected(false);
        } else {
            setCarOperatingPanelOthersSelected(false);
            setCarOperatingPanelSteelSelected(false);
        }
    }
    
    const handleHallOperatingPanel = (data) =>{
        if(data == "Hairline Stainless Steel"){
            setHallOperatingPanelSteelSelected(true);
            setHallOperatingPanelOthersSelected(false);
        } else if (data == "Others"){
            setHallOperatingPanelOthersSelected(true);
            setHallOperatingPanelSteelSelected(false);
        } else {
            setHallOperatingPanelOthersSelected(false);
            setHallOperatingPanelSteelSelected(false);
        }
    }

    const onSubmit = (data) => {
        Notiflix.Confirm.show(
            'Confirm to next step',
            'Do you want to continue?',
            'Yes',
            'No',
            async () => {
                let dao = new ElevatorModelDao();
                // if (isEditing) {
                //     data = {
                //         ...data,
                //         carOperatingPanelSpec: watch("carOperatingPanelMaterial") == "Device's Default" ? "" : watch("carOperatingPanelSpec"),
                //         carOperatingPanelOthers: watch("carOperatingPanelMaterial") == "Device's Default" ? "" : (watch("carOperatingPanelSpec") == "Others" ? watch("carOperatingPanelOthers") : ""),
                //         hallOperatingPanelSpec: watch("hallOperatingPanelMaterial") == "Device's Default" ? "" : watch("hallOperatingPanelSpec"),
                //         hallOperatingPanelOthers: watch("hallOperatingPanelMaterial") == "Device's Default" ? "" : (watch("hallOperatingPanelSpec") == "Others" ? watch("hallOperatingPanelOthers") : ""),
                //         shaftOthers: watch("shaftMaterial") == "Others" ? watch("shaftOthers") : "",
                //         landingFrameOthers: watch("landingFrameMaterial") == "Others" ? watch("landingFrameOthers") : "",
                //         landingPanelOthers: watch("landingPanelMaterial") == "Others" ? watch("landingPanelOthers") : "",
                //         controllerBoxOthers: watch("controllerBoxMaterial") == "Others" ? watch("controllerBoxOthers") : "",
                //         id: state.details.id,
                //     }
                //     console.log(data);
                //     handleNext();
                //     await dao.updateElevatorModelStep3(data, token).then(responseJson => {
                //         if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                //             Report.success('Success', 'Successfully updated model step 3.', 'Okay', handleNext());
                //         } else {
                //             Report.failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                //         }
                //     })
                //     }else {
                //         data = {
                //             ...data,
                //             carOperatingPanelSpec: watch("carOperatingPanelMaterial") == "Device's Default" ? "" : watch("carOperatingPanelSpec"),
                //             carOperatingPanelOthers: watch("carOperatingPanelMaterial") == "Device's Default" ? "" : (watch("carOperatingPanelSpec") == "Others" ? watch("carOperatingPanelOthers") : ""),
                //             hallOperatingPanelSpec: watch("hallOperatingPanelMaterial") == "Device's Default" ? "" : watch("hallOperatingPanelSpec"),
                //             hallOperatingPanelOthers: watch("hallOperatingPanelMaterial") == "Device's Default" ? "" : (watch("hallOperatingPanelSpec") == "Others" ? watch("hallOperatingPanelOthers") : ""),
                //             shaftOthers: watch("shaftMaterial") == "Others" ? watch("shaftOthers") : "",
                //             landingFrameOthers: watch("landingFrameMaterial") == "Others" ? watch("landingFrameOthers") : "",
                //             landingPanelOthers: watch("landingPanelMaterial") == "Others" ? watch("landingPanelOthers") : "",
                //             controllerBoxOthers: watch("controllerBoxMaterial") == "Others" ? watch("controllerBoxOthers") : "",
                //             id: elevatorId,
                //         }  
                //         console.log(data)
                //         await dao.createElevatorModelStep3(data, token).then((responseJson) => {
                //             if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                //               let model = responseJson[ApiKey._API_DATA_KEY];
                //               Notiflix.Report.init({ plainText: false });
                //               Notiflix.Report.success(
                //                 "Success",
                //                 `Successfully created model step 3.`,
                //                 "Okay",
                //                 handleNext()
                //               );setStep3(data);
                //             } else {
                //               Notiflix.Report.failure(
                //                 "Error",
                //                 responseJson[ApiKey._API_MESSAGE_KEY]
                //               );
                //             }
                //         });
                //     }
            }
        )
    }
    useEffect(() => {
        if (state?.details) {
          console.log(state)
          reset(state?.details);
        }
        handleShaft(state?.details?.shaft_material);
        handleLandingFrame(state?.details?.landing_frame_material);
        handleLandingDoorPanelType(state?.details?.landing_panel_type);
        handleLandingPanel(state?.details?.landing_panel_material);
        handleControllerBox(state?.details?.controller_box_material);
        handleCarOperatingPanel(state?.details?.car_operating_panel_material);
        handleHallOperatingPanel(state?.details?.hall_operating_panel_material);

      document.title = `NECS | ${isEditing ? 'Update' : 'Create'} Elevator Model`;
    }, [reset, isEditing, state])

    useEffect(() => {
        if(step3 != null) {
            handleShaft(step3?.shaftMaterial);
            handleLandingFrame(step3?.landingFrameMaterial);
            handleLandingDoorPanelType(step3?.landingPanelType);
            handleLandingPanel(step3?.landingPanelMaterial);
            handleControllerBox(step3?.controllerBoxMaterial);
            handleCarOperatingPanel(step3?.carOperatingPanelMaterial);
            handleHallOperatingPanel(step3?.hallOperatingPanelMaterial);
        }              
    }, [step3]);

    return(
    <>
    <form id="elevatorModelStep3" onSubmit={handleSubmit(onSubmit)}>
    <Row style={{backgroundColor:"#F5F5F5", margin: "10px"}}>
        {/* <Col md={12}>
            <form id="liftShaftFacialPlate">
            <Row style={{backgroundColor:"#F5F5F5", margin: "10px"}}>
                <Col md={10}>
                  <h2 className="page-subheader" style={{borderBottom: "2px solid #e4e6e8"}}>
                      Lift Shaft Facial Plate
                  </h2>
                </Col>
                <Col md={2}>
                <button type="reset" className='btn btn-default d-flex-center' style={{color:"#f64e60" , borderColor:"#f64e60", justifyContent:"center", margin:"auto", width:"100%"}}>Reset Fields &nbsp; <i className="fas fa-undo"></i></button>
                </Col>
                <Col md={6}>
                    <label className="custom-input-label">Material</label>
                    <CustomSelect
                          control={control}
                          name="shaftMaterial"
                          label="Lift Shaft Material"
                          placeholder="Choose the material"
                          options={LiftShaftMaterial}
                          defaultValue={state?.details ? state.details.shaft_material : step3?.shaftMaterial}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                          rules={{
                              required: true,
                          }}
                          onChange={handleShaft}
                        />
                </Col>
                {shaftGalvanizedSelected && (
                    <Col md={6}>
                        <label className="custom-input-label">Galvanized</label>
                        <CustomSelect
                              control={control}
                              name="shaftSpec"
                              label="Hairline Stainless Galvanized"
                              placeholder="Please select"
                              options={Galvanized}
                              defaultValue={state?.details ? state.details.shaft_spec : step3?.shaftSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {shaftSteelSelected && (
                    <Col md={6}>
                        <label className="custom-input-label">Hairline Stainless Steel</label>
                        <CustomSelect
                              control={control}
                              name="shaftSpec"
                              label="Hairline Stainless Steel"
                              placeholder="Choose the hairline stainless steel"
                              options={HairlineStainlessSteel}
                              defaultValue={state?.details ? state.details.shaft_spec : step3?.shaftSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {shaftCoatedSelected && (
                    <Col md={6}>
                        <label className="custom-input-label">Powder Coated</label>
                        <CustomSelect
                              control={control}
                              name="shaftSpec"
                              label="Powder Coated"
                              placeholder="Choose the powder coated"
                              options={PowderCoated}
                              defaultValue={state?.details ? state.details.shaft_spec : step3?.shaftSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {shaftPaintSelected && (
                    <Col md={6}>
                        <label className="custom-input-label">Spray Paint</label>
                        <CustomSelect
                              control={control}
                              name="shaftSpec"
                              label="Spray Paint"
                              placeholder="Choose the spray paint"
                              options={SprayPaint}
                              defaultValue={state?.details ? state.details.shaft_spec : step3?.shaftSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {shaftOthersSelected && (
                    <Col md={6}>
                        <label className="custom-input-label">Others</label>
                            <CustomInput
                              {...register("shaftOthers", {
                                required: false,
                              })}
                              name="shaftOthers"
                              label="Others"
                              defaultValue={state?.details ? state.details.shaft_others : step3?.shaftOthers}
                            />
                    </Col>
                )}

            </Row>
        </form></Col> */}{/*end of lift shaft facial plate*/}
        {/* <Col md={12}>
            <form id="landingDoorFrame">
            <Row style={{backgroundColor:"#F5F5F5", margin: "10px"}}>
                <Col md={10}>
                  <h2 className="page-subheader" style={{borderBottom: "2px solid #e4e6e8"}}>
                      Landing Door Frame
                  </h2>
                </Col>
                <Col md={2}>
                <button type="reset" className='btn btn-default d-flex-center' style={{color:"#f64e60" , borderColor:"#f64e60", justifyContent:"center", margin:"auto", width:"100%"}}>Reset Fields &nbsp; <i className="fas fa-undo"></i></button>
                </Col>
                <Col md={6}>
                    <label className="custom-input-label">Material</label>
                    <CustomSelect
                          control={control}
                          name="landingFrameMaterial"
                          label="Lift Shaft Material"
                          placeholder="Choose the material"
                          options={LandingDoorFrameMaterial}
                          defaultValue={state?.details ? state.details.landing_frame_material : step3?.landingFrameMaterial}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                          rules={{
                              required: true,
                          }}
                          onChange={handleLandingFrame}
                        />
                </Col>
                {landingFrameSteelSelected && (
                    <Col md={6}>
                        <label className="custom-input-label">Hairline Stainless Steel</label>
                        <CustomSelect
                              control={control}
                              name="landingFrameSpec"
                              label="Hairline Stainless Steel"
                              placeholder="Choose the hairline stainless steel"
                              options={HairlineStainlessSteel}
                              defaultValue={state?.details ? state.details.landing_frame_spec : step3?.landingFrameSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {landingFrameCoatedSelected && (
                    <Col md={6}>
                        <label className="custom-input-label">Powder Coated</label>
                        <CustomSelect
                              control={control}
                              name="landingFrameSpec"
                              label="Powder Coated"
                              placeholder="Choose the powder coated"
                              options={PowderCoated}
                              defaultValue={state?.details ? state.details.landing_frame_spec : step3?.landingFrameSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {landingFramePaintSelected && (
                    <Col md={6}>
                        <label className="custom-input-label">Spray Paint</label>
                        <CustomSelect
                              control={control}
                              name="landingFrameSpec"
                              label="Spray Paint"
                              placeholder="Choose the spray paint"
                              options={SprayPaint}
                              defaultValue={state?.details ? state.details.landing_frame_spec : step3?.landingFrameSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {landingFrameFinishesSelected && (
                    <Col md={6}>
                        <label className="custom-input-label">Bare Finishes</label>
                        <CustomSelect
                              control={control}
                              name="landingFrameSpec"
                              label="Bare Finishes"
                              placeholder="Choose the bare finishes"
                              options={BareFinishes}
                              defaultValue={state?.details ? state.details.landing_frame_spec : step3?.landingFrameSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {landingFrameOthersSelected && (
                    <Col md={6}>
                        <label className="custom-input-label">Others</label>
                            <CustomInput
                              {...register("landingFrameOthers", {
                                required: false,
                              })}
                              name="landingFrameOthers"
                              label="Others"
                              defaultValue={state?.details ? state.details.landing_frame_others : step3?.landingFrameOthers}
                            />
                    </Col>
                )}

            </Row> 
        </form></Col>*/}{/*end of landing door frame*/}

        {/* <Col md={12}>
            <h2 className="page-subheader" style={{borderBottom: "2px solid #e4e6e8", marginLeft:"20px"}}>
                Landing Door Panel
            </h2>
            <Card style={{marginLeft:"20px"}}>
                <Row style={{margin: "10px"}}>
                    <Col md={4}>
                        <CustomSelect
                            control={control}
                            name="landingPanelType"
                            label="Car Wall Panel"
                            placeholder="Choose the side of car wall panel"
                            options={DoorPanelTypes}
                            defaultValue={state?.details ? state.details.landing_panel_type : step3?.landingPanelType}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                            rules={{
                                required: true,
                            }}
                            onChange={handleLandingDoorPanelType}
                        />
                    </Col>
                </Row>
                {landingDoorTypeSelected && (
                <form id="landingPanelSolidDoor">
                    <Row style={{margin: "10px"}}>
                        <Col md={10}>
                            <h3 className="page-subheader">
                                {landingDoorType}
                            </h3>
                        </Col>
                        <Col md={2}>
                            <button type="reset" className='btn btn-default d-flex-center' style={{color:"#f64e60" , borderColor:"#f64e60", justifyContent:"center", margin:"auto", width:"100%"}}>Reset Fields &nbsp; <i className="fas fa-undo"></i></button>
                        </Col>
                        <Col md={6}>
                            <label className="custom-input-label">Material</label>
                            <CustomSelect
                                  control={control}
                                  name="landingPanelMaterial"
                                  label="Landing Door Panel Material"
                                  placeholder="Choose the material"
                                  options={FourTypesOfMaterial}
                                  defaultValue={state?.details ? state.details.landing_panel_material : step3?.landingPanelMaterial}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                                  rules={{
                                      required: true,
                                  }}
                                  onChange={handleLandingPanel}
                                />
                        </Col>
                        {landingPanelSteelSelected && (
                            <Col md={6}>
                                <label className="custom-input-label">Hairline Stainless Steel</label>
                                <CustomSelect
                                      control={control}
                                      name="landingPanelSpec"
                                      label="Hairline Stainless Steel"
                                      placeholder="Choose the hairline stainless steel"
                                      options={HairlineStainlessSteel}
                                      defaultValue={state?.details ? state.details.landing_panel_spec : step3?.landingPanelSpec}
                                      rules={{
                                          required: true,
                                      }}
                                    />
                            </Col>
                        )}
                        {landingPanelCoatedSelected && (
                            <Col md={6}>
                                <label className="custom-input-label">Powder Coated</label>
                                <CustomSelect
                                      control={control}
                                      name="landingPanelSpec"
                                      label="Powder Coated"
                                      placeholder="Choose the powder coated"
                                      options={PowderCoated}
                                      defaultValue={state?.details ? state.details.landing_panel_spec : step3?.landingPanelSpec}
                                      rules={{
                                          required: true,
                                      }}
                                    />
                            </Col>
                        )}
                        {landingPanelPaintSelected && (
                            <Col md={6}>
                                <label className="custom-input-label">Spray Paint</label>
                                <CustomSelect
                                      control={control}
                                      name="landingPanelSpec"
                                      label="Spray Paint"
                                      placeholder="Choose the spray paint"
                                      options={SprayPaint}
                                      defaultValue={state?.details ? state.details.landing_panel_spec : step3?.landingPanelSpec}
                                      rules={{
                                          required: true,
                                      }}
                                    />
                            </Col>
                        )}
                        {landingPanelOthersSelected && (
                            <Col md={6}>
                                <label className="custom-input-label">Others</label>
                                    <CustomInput
                                      {...register("landingPanelOthers", {
                                        required: false,
                                      })}
                                      name="landingPanelOthers"
                                      label="Others"
                                      defaultValue={state?.details ? state.details.landing_pane_others : step3?.landingPanelOthers}
                                    />
                            </Col>
                        )}

                </Row>
            </form>)}</Card> 
        </Col>*/}{/*end of landing door panel*/}

        {/* <Col md={12}>
            <form id="controllerBox">
                <Row style={{backgroundColor:"#F5F5F5", margin: "10px"}}>
                    <Col md={10}>
                      <h2 className="page-subheader" style={{borderBottom: "2px solid #e4e6e8"}}>
                          Controller Box
                      </h2>
                    </Col>
                    <Col md={2}>
                    <button type="reset" className='btn btn-default d-flex-center' style={{color:"#f64e60" , borderColor:"#f64e60", justifyContent:"center", margin:"auto", width:"100%"}}>Reset Fields &nbsp; <i className="fas fa-undo"></i></button>
                    </Col>
                    <Col md={6}>
                        <label className="custom-input-label">Material</label>
                        <CustomSelect
                            control={control}
                            name="controllerBoxMaterial"
                            label="Lift Shaft Material"
                            placeholder="Choose the material"
                            options={FourTypesOfMaterial}
                            defaultValue={state?.details ? state.details.controller_box_material : step3?.controllerBoxMaterial}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                            rules={{
                                required: true,
                            }}
                            onChange={handleControllerBox}
                        />
                    </Col>
                    {controllerBoxSteelSelected && (
                        <Col md={6}>
                            <label className="custom-input-label">Hairline Stainless Steel</label>
                            <CustomSelect
                                  control={control}
                                  name="controllerBoxSpec"
                                  label="Hairline Stainless Steel"
                                  placeholder="Choose the hairline stainless steel"
                                  options={HairlineStainlessSteel}
                                  defaultValue={state?.details ? state.details.controller_box_spec : step3?.controllerBoxSpec}
                                  rules={{
                                      required: true,
                                  }}
                                />
                        </Col>
                    )}
                    {controllerBoxCoatedSelected && (
                        <Col md={6}>
                            <label className="custom-input-label">Powder Coated</label>
                            <CustomSelect
                                  control={control}
                                  name="controllerBoxSpec"
                                  label="Powder Coated"
                                  placeholder="Choose the powder coated"
                                  options={PowderCoated}
                                  defaultValue={state?.details ? state.details.controller_box_spec : step3?.controllerBoxSpec}
                                  rules={{
                                      required: true,
                                  }}
                                />
                        </Col>
                    )}
                    {controllerBoxPaintSelected && (
                        <Col md={6}>
                            <label className="custom-input-label">Spray Paint</label>
                            <CustomSelect
                                  control={control}
                                  name="controllerBoxSpec"
                                  label="Spray Paint"
                                  placeholder="Choose the spray paint"
                                  options={SprayPaint}
                                  defaultValue={state?.details ? state.details.controller_box_spec : step3?.controllerBoxSpec}
                                  rules={{
                                      required: true,
                                  }}
                                />
                        </Col>
                    )}
                    {controllerBoxOthersSelected && (
                        <Col md={6}>
                            <label className="custom-input-label">Others</label>
                                <CustomInput
                                  {...register("controllerBoxOthers", {
                                    required: false,
                                  })}
                                  name="controllerBoxOthers"
                                  label="Others"
                                  defaultValue={state?.details ? state.details.controller_box_others : step3?.controllerBoxOthers}
                                />
                        </Col>
                    )}

                </Row>
            </form>
        </Col> */}{/*end of controller box*/}
        
        {/* <Col md={12}>
            <form id="carOperatingPanel">
                <Row style={{backgroundColor:"#F5F5F5", margin: "10px"}}>
                    <Col md={10}>
                      <h2 className="page-subheader" style={{borderBottom: "2px solid #e4e6e8"}}>
                          Car Operating Panel
                      </h2>
                    </Col>
                    <Col md={2}>
                    <button type="reset" className='btn btn-default d-flex-center' style={{color:"#f64e60" , borderColor:"#f64e60", justifyContent:"center", margin:"auto", width:"100%"}}>Reset Fields &nbsp; <i className="fas fa-undo"></i></button>
                    </Col>
                    <Col md={6}>
                        <label className="custom-input-label">Material</label>
                        <CustomSelect
                              control={control}
                              name="carOperatingPanelMaterial"
                              label="Car Operating Panel Material"
                              placeholder="Choose the material"
                              options={ThreeTypesOfMaterial}
                              defaultValue={state?.details ? state.details.car_operating_panel_material : step3?.carOperatingPanelMaterial}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                              rules={{
                                  required: true,
                              }}
                              onChange={handleCarOperatingPanel}
                            />
                    </Col>
                    {carOperatingPanelSteelSelected && (
                        <Col md={6}>
                            <label className="custom-input-label">Hairline Stainless Steel</label>
                            <CustomSelect
                                  control={control}
                                  name="carOperatingPanelSpec"
                                  label="Hairline Stainless Steel"
                                  placeholder="Choose the hairline stainless steel"
                                  options={HairlineStainlessSteel}
                                  defaultValue={state?.details ? state.details.car_operating_panel_spec : step3?.carOperatingPanelSpec}
                                  rules={{
                                      required: true,
                                  }}
                                />
                        </Col>
                    )}
                    {carOperatingPanelOthersSelected && (
                        <Col md={6}>
                            <label className="custom-input-label">Others</label>
                                <CustomInput
                                  {...register("carOperatingPanelOthers", {
                                    required: false,
                                  })}
                                  name="carOperatingPanelOthers"
                                  label="Others"
                                  defaultValue={state?.details ? state.details.car_operating_panel_others : step3?.carOperatingPanelOthers}
                                />
                        </Col>
                    )}

                </Row>
            </form> 
        </Col>*/}{/*end of car operating panel*/}

        {/* <Col md={12}>
            <form id="hallOperatingPanel">
                <Row style={{backgroundColor:"#F5F5F5", margin: "10px"}}>
                    <Col md={10}>
                      <h2 className="page-subheader" style={{borderBottom: "2px solid #e4e6e8"}}>
                          Hall Operating Panel
                      </h2>
                    </Col>
                    <Col md={2}>
                    <button type="reset" className='btn btn-default d-flex-center' style={{color:"#f64e60" , borderColor:"#f64e60", justifyContent:"center", margin:"auto", width:"100%"}}>Reset Fields &nbsp; <i className="fas fa-undo"></i></button>
                    </Col>
                    <Col md={6}>
                        <label className="custom-input-label">Material</label>
                        <CustomSelect
                              control={control}
                              name="hallOperatingPanelMaterial"
                              label="Hall Operating Panel Material"
                              placeholder="Choose the material"
                              options={ThreeTypesOfMaterial}
                              defaultValue={state?.details ? state.details.hall_operating_panel_material : step3?.hallOperatingPanelMaterial}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                              rules={{
                                  required: true,
                              }}
                              onChange={handleHallOperatingPanel}
                            />
                    </Col>
                    {hallOperatingPanelSteelSelected && (
                        <Col md={6}>
                            <label className="custom-input-label">Hairline Stainless Steel</label>
                            <CustomSelect
                                  control={control}
                                  name="hallOperatingPanelSpec"
                                  label="Hairline Stainless Steel"
                                  placeholder="Choose the hairline stainless steel"
                                  options={HairlineStainlessSteel}
                                  defaultValue={state?.details ? state.details.hall_operating_panel_spec : step3?.hallOperatingPanelSpec}
                                  rules={{
                                      required: true,
                                  }}
                                />
                        </Col>
                    )}
                    {hallOperatingPanelOthersSelected && (
                        <Col md={6}>
                            <label className="custom-input-label">Others</label>
                                <CustomInput
                                  {...register("hallOperatingPanelOthers", {
                                    required: false,
                                  })}
                                  name="hallOperatingPanelOthers"
                                  label="Others"
                                  defaultValue={state?.details ? state.details.hall_operating_panel_others : step3?.hallOperatingPanelOthers}
                                />
                        </Col>
                    )}

                </Row>
            </form>
        </Col>*/}{/*end of car operating panel */}


        <Col md={12}>
            <br />
            <button type="button" className='btn btn-default d-flex-center' style={{color:"#f64e60" , borderColor:"#f64e60", justifyContent:"center", margin:"auto", width:"100%"}} onClick={handleBack}>Back</button>
            <br />
            {isEmpty(state?.details?.deleted_at) && (<button type="submit" className="btn btn-themed btn-block">Continue</button>)}
            {(state?.details?.deleted_at) && (<button className="btn btn-themed btn-block" onClick={handleNext}>Next</button>)}
            <br />
        </Col>
      
    </Row></form>
    </>)
} 

export default FrontReturnDoorPanelToeGuard;