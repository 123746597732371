import React, { useEffect, forwardRef, useState } from "react";
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Config, ConfigEnum } from 'util/Config';
import ElevatorModelDao from "data/ElevatorModelDao";
import { useForm, useFieldArray } from "react-hook-form";
import Notiflix , { Report, Loading } from "notiflix";
import NavigationButton from 'components/buttons/NavigationButton';
import { ApiKey, HairlineStainlessSteel, PowderCoated, SprayPaint, Glass,
         Mirror, BareFinishes, Floor, Material, WallPanelSide, DoorPanelTypes, YesOrNo, 
         SevenTypesOfMaterial, FourTypesOfMaterial, ThreeTypesOfMaterial,
         LandingDoorFrameMaterial, LiftShaftMaterial, Galvanized } from "util/Constant";
import CustomInput from "components/form/CustomInput";
import CustomSelect from "components/form/CustomSelect";
import Checkbox from "components/form/Checkbox";
import CustomCheckboxList from "components/form/CustomCheckboxList";
import { isEmpty } from "lodash";
import { CoPresent } from "@mui/icons-material";

const WallPanelCeilingFloor = (props) =>{
    const { register, control, handleSubmit, reset, errors, watch, successCallback } = useForm();
    
    const history = useHistory();
    const { id } = useParams();
    const isEditing = id;
    const { state } = useLocation();

    const { handleNext, handleBack, elevatorId, step2, setStep2 } = props;
    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    const [personSelected, setPersonSelected] = useState(false);
    const [materialSelected, setMaterialSelected] = useState(false);
    const [panelSideSelected, setPanelSideSelected] = useState(false);
    const [panelSide, setPanelSide] = useState(null);
    const [carDoorTypeSelected, setCarDoorTypeSelected] = useState(false);
    const [carDoorType, setCarDoorType] = useState(null);

    const [carWallPanelSteelSelected, setCarWallPanelSteelSelected] = useState(false);
    const [carWallPanelCoatedSelected, setCarWallPanelCoatedSelected] = useState(false);
    const [carWallPanelPaintSelected, setCarWallPanelPaintSelected] = useState(false);
    const [carWallPanelGlassSelected, setCarWallPanelGlassSelected] = useState(false);
    const [carWallPanelMirrorSelected, setCarWallPanelMirrorSelected] = useState(false);
    const [carWallPanelFinishesSelected, setCarWallPanelFinishesSelected] = useState(false);
    const [carWallPanelOthersSelected, setCarWallPanelOthersSelected] = useState(false);

    const [carCeilingSteelSelected, setCarCeilingSteelSelected] = useState(false);
    const [carCeilingCoatedSelected, setCarCeilingCoatedSelected] = useState(false);
    const [carCeilingPaintSelected, setCarCeilingPaintSelected] = useState(false);
    const [carCeilingGlassSelected, setCarCeilingGlassSelected] = useState(false);
    const [carCeilingMirrorSelected, setCarCeilingMirrorSelected] = useState(false);
    const [carCeilingFinishesSelected, setCarCeilingFinishesSelected] = useState(false);
    const [carCeilingOthersSelected, setCarCeilingOthersSelected] = useState(false);

    const [carDoorFrontSteelSelected, setCarDoorFrontSteelSelected] = useState(false);
    const [carDoorFrontCoatedSelected, setCarDoorFrontCoatedSelected] = useState(false);
    const [carDoorFrontPaintSelected, setCarDoorFrontPaintSelected] = useState(false);
    const [carDoorFrontGlassSelected, setCarDoorFrontGlassSelected] = useState(false);
    const [carDoorFrontMirrorSelected, setCarDoorFrontMirrorSelected] = useState(false);
    const [carDoorFrontFinishesSelected, setCarDoorFrontFinishesSelected] = useState(false);
    const [carDoorFrontOthersSelected, setCarDoorFrontOthersSelected] = useState(false);

    const [carDoorPanelSteelSelected, setCarDoorPanelSteelSelected] = useState(false);
    const [carDoorPanelCoatedSelected, setCarDoorPanelCoatedSelected] = useState(false);
    const [carDoorPanelPaintSelected, setCarDoorPanelPaintSelected] = useState(false);
    const [carDoorPanelOthersSelected, setCarDoorPanelOthersSelected] = useState(false);

    const [carDoorGuardSteelSelected, setCarDoorGuardSteelSelected] = useState(false);
    const [carDoorGuardCoatedSelected, setCarDoorGuardCoatedSelected] = useState(false);
    const [carDoorGuardPaintSelected, setCarDoorGuardPaintSelected] = useState(false);
    const [carDoorGuardOthersSelected, setCarDoorGuardOthersSelected] = useState(false);

    const [landingDoorTypeSelected, setLandingDoorTypeSelected] = useState(false);
    const [landingDoorType, setLandingDoorType] = useState(null);
    
    const [shaftGalvanizedSelected, setShaftGalvanizedSelected] = useState(false);
    const [shaftSteelSelected, setShaftSteelSelected] = useState(false);
    const [shaftCoatedSelected, setShaftCoatedSelected] = useState(false);
    const [shaftPaintSelected, setShaftPaintSelected] = useState(false);
    const [shaftOthersSelected, setShaftOthersSelected] = useState(false);

    const [landingFrameSteelSelected, setLandingFrameSteelSelected] = useState(false);
    const [landingFrameCoatedSelected, setLandingFrameCoatedSelected] = useState(false);
    const [landingFramePaintSelected, setLandingFramePaintSelected] = useState(false);
    const [landingFrameFinishesSelected, setLandingFrameFinishesSelected] = useState(false);
    const [landingFrameOthersSelected, setLandingFrameOthersSelected] = useState(false);

    const [landingPanelSteelSelected, setLandingPanelSteelSelected] = useState(false);
    const [landingPanelCoatedSelected, setLandingPanelCoatedSelected] = useState(false);
    const [landingPanelPaintSelected, setLandingPanelPaintSelected] = useState(false);
    const [landingPanelOthersSelected, setLandingPanelOthersSelected] = useState(false);

    const [controllerBoxSteelSelected, setControllerBoxSteelSelected] = useState(false);
    const [controllerBoxCoatedSelected, setControllerBoxCoatedSelected] = useState(false);
    const [controllerBoxPaintSelected, setControllerBoxPaintSelected] = useState(false);
    const [controllerBoxOthersSelected, setControllerBoxOthersSelected] = useState(false);

    const [carOperatingPanelSteelSelected, setCarOperatingPanelSteelSelected] = useState(false);
    const [carOperatingPanelOthersSelected, setCarOperatingPanelOthersSelected] = useState(false);

    const [hallOperatingPanelSteelSelected, setHallOperatingPanelSteelSelected] = useState(false);
    const [hallOperatingPanelOthersSelected, setHallOperatingPanelOthersSelected] = useState(false);


    //material, spec, others
    const handlePersonSelected = (data) =>{
        if(data == "Niche"){
            setPersonSelected(true);
        }
        else {
            setPersonSelected(false);
            setMaterialSelected(false);
        }
    }

    const handleMaterialSelected = (data) =>{
        if(data == "Vinyl Sheets" || data == "Others")
            setMaterialSelected(true);
        else setMaterialSelected(false);
    }

    const handleWallPanelSide = (data) =>{
        if(data == "Side A : Left" || data == "Side B : Rear" || data == "Side C : Right"){
            setPanelSide(data);
            setPanelSideSelected(true);
        }
        else {
            setPanelSide(null);
            setPanelSideSelected(false);
        }
    }

    const handleCarDoorPanelType = (data) =>{
        if(data == "Solid Door" || data == "Big Vision Door" || data == "Frameless Door" || data == "Others"){
            setCarDoorType(data);
            setCarDoorTypeSelected(true);
        }
        else {
            setCarDoorType(null);
            setCarDoorTypeSelected(false);
        }
    }

    const handleCarWallPanel = (data) =>{
        if(data == "Hairline Stainless Steel"){
            setCarWallPanelSteelSelected(true);
            setCarWallPanelCoatedSelected(false);
            setCarWallPanelPaintSelected(false);
            setCarWallPanelGlassSelected(false);
            setCarWallPanelMirrorSelected(false);
            setCarWallPanelFinishesSelected(false);
            setCarWallPanelOthersSelected(false);
        } else if (data == "Power Coated"){
            setCarWallPanelCoatedSelected(true);
            setCarWallPanelSteelSelected(false);
            setCarWallPanelPaintSelected(false);
            setCarWallPanelGlassSelected(false);
            setCarWallPanelMirrorSelected(false);
            setCarWallPanelFinishesSelected(false);
            setCarWallPanelOthersSelected(false);
        } else if (data == "Spray Paint"){
            setCarWallPanelPaintSelected(true);
            setCarWallPanelSteelSelected(false);
            setCarWallPanelCoatedSelected(false);
            setCarWallPanelGlassSelected(false);
            setCarWallPanelMirrorSelected(false);
            setCarWallPanelFinishesSelected(false);
            setCarWallPanelOthersSelected(false);
        } else if (data == "Glass"){
            setCarWallPanelGlassSelected(true);
            setCarWallPanelSteelSelected(false);
            setCarWallPanelCoatedSelected(false);
            setCarWallPanelPaintSelected(false);
            setCarWallPanelMirrorSelected(false);
            setCarWallPanelFinishesSelected(false);
            setCarWallPanelOthersSelected(false);
        } else if (data == "Mirror"){
            setCarWallPanelMirrorSelected(true);                
            setCarWallPanelSteelSelected(false);
            setCarWallPanelCoatedSelected(false);
            setCarWallPanelPaintSelected(false);
            setCarWallPanelGlassSelected(false);
            setCarWallPanelFinishesSelected(false);
            setCarWallPanelOthersSelected(false);
        } else if (data == "Bare Finishes"){
            setCarWallPanelFinishesSelected(true);
            setCarWallPanelSteelSelected(false);
            setCarWallPanelCoatedSelected(false);
            setCarWallPanelPaintSelected(false);
            setCarWallPanelGlassSelected(false);
            setCarWallPanelMirrorSelected(false);
            setCarWallPanelOthersSelected(false);
        } else if (data == "Others"){
            setCarWallPanelOthersSelected(true);
            setCarWallPanelSteelSelected(false);
            setCarWallPanelCoatedSelected(false);
            setCarWallPanelPaintSelected(false);
            setCarWallPanelGlassSelected(false);
            setCarWallPanelMirrorSelected(false);
            setCarWallPanelFinishesSelected(false);
        }
    }
    
    const handleCarCeiling = (data) =>{
        if(data == "Hairline Stainless Steel"){
            setCarCeilingSteelSelected(true);
            setCarCeilingCoatedSelected(false);
            setCarCeilingPaintSelected(false);
            setCarCeilingGlassSelected(false);
            setCarCeilingMirrorSelected(false);
            setCarCeilingFinishesSelected(false);
            setCarCeilingOthersSelected(false);
        } else if (data == "Power Coated"){
            setCarCeilingCoatedSelected(true);
            setCarCeilingSteelSelected(false);
            setCarCeilingPaintSelected(false);
            setCarCeilingGlassSelected(false);
            setCarCeilingMirrorSelected(false);
            setCarCeilingFinishesSelected(false);
            setCarCeilingOthersSelected(false);
        } else if (data == "Spray Paint"){
            setCarCeilingPaintSelected(true);
            setCarCeilingSteelSelected(false);
            setCarCeilingCoatedSelected(false);
            setCarCeilingGlassSelected(false);
            setCarCeilingMirrorSelected(false);
            setCarCeilingFinishesSelected(false);
            setCarCeilingOthersSelected(false);
        } else if (data == "Glass"){
            setCarCeilingGlassSelected(true);
            setCarCeilingSteelSelected(false);
            setCarCeilingCoatedSelected(false);
            setCarCeilingPaintSelected(false);
            setCarCeilingMirrorSelected(false);
            setCarCeilingFinishesSelected(false);
            setCarCeilingOthersSelected(false);
        } else if (data == "Mirror"){
            setCarCeilingMirrorSelected(true);                
            setCarCeilingSteelSelected(false);
            setCarCeilingCoatedSelected(false);
            setCarCeilingPaintSelected(false);
            setCarCeilingGlassSelected(false);
            setCarCeilingFinishesSelected(false);
            setCarCeilingOthersSelected(false);
        } else if (data == "Bare Finishes"){
            setCarCeilingFinishesSelected(true);
            setCarCeilingSteelSelected(false);
            setCarCeilingCoatedSelected(false);
            setCarCeilingPaintSelected(false);
            setCarCeilingGlassSelected(false);
            setCarCeilingMirrorSelected(false);
            setCarCeilingOthersSelected(false);
        } else if (data == "Others"){
            setCarCeilingOthersSelected(true);
            setCarCeilingSteelSelected(false);
            setCarCeilingCoatedSelected(false);
            setCarCeilingPaintSelected(false);
            setCarCeilingGlassSelected(false);
            setCarCeilingMirrorSelected(false);
            setCarCeilingFinishesSelected(false);
        }
    }
    
    const handleCarDoorFront = (data) =>{
        if(data == "Hairline Stainless Steel"){
            setCarDoorFrontSteelSelected(true);
            setCarDoorFrontCoatedSelected(false);
            setCarDoorFrontPaintSelected(false);
            setCarDoorFrontGlassSelected(false);
            setCarDoorFrontMirrorSelected(false);
            setCarDoorFrontFinishesSelected(false);
            setCarDoorFrontOthersSelected(false);
        } else if (data == "Power Coated"){
            setCarDoorFrontCoatedSelected(true);
            setCarDoorFrontSteelSelected(false);
            setCarDoorFrontPaintSelected(false);
            setCarDoorFrontGlassSelected(false);
            setCarDoorFrontMirrorSelected(false);
            setCarDoorFrontFinishesSelected(false);
            setCarDoorFrontOthersSelected(false);
        } else if (data == "Spray Paint"){
            setCarDoorFrontPaintSelected(true);
            setCarDoorFrontSteelSelected(false);
            setCarDoorFrontCoatedSelected(false);
            setCarDoorFrontGlassSelected(false);
            setCarDoorFrontMirrorSelected(false);
            setCarDoorFrontFinishesSelected(false);
            setCarDoorFrontOthersSelected(false);
        } else if (data == "Glass"){
            setCarDoorFrontGlassSelected(true);
            setCarDoorFrontSteelSelected(false);
            setCarDoorFrontCoatedSelected(false);
            setCarDoorFrontPaintSelected(false);
            setCarDoorFrontMirrorSelected(false);
            setCarDoorFrontFinishesSelected(false);
            setCarDoorFrontOthersSelected(false);
        } else if (data == "Mirror"){
            setCarDoorFrontMirrorSelected(true);                
            setCarDoorFrontSteelSelected(false);
            setCarDoorFrontCoatedSelected(false);
            setCarDoorFrontPaintSelected(false);
            setCarDoorFrontGlassSelected(false);
            setCarDoorFrontFinishesSelected(false);
            setCarDoorFrontOthersSelected(false);
        } else if (data == "Bare Finishes"){
            setCarDoorFrontFinishesSelected(true);
            setCarDoorFrontSteelSelected(false);
            setCarDoorFrontCoatedSelected(false);
            setCarDoorFrontPaintSelected(false);
            setCarDoorFrontGlassSelected(false);
            setCarDoorFrontMirrorSelected(false);
            setCarDoorFrontOthersSelected(false);
        } else if (data == "Others"){
            setCarDoorFrontOthersSelected(true);
            setCarDoorFrontSteelSelected(false);
            setCarDoorFrontCoatedSelected(false);
            setCarDoorFrontPaintSelected(false);
            setCarDoorFrontGlassSelected(false);
            setCarDoorFrontMirrorSelected(false);
            setCarDoorFrontFinishesSelected(false);
        }
    }
    
    const handleCarDoorPanel = (data) =>{
        if(data == "Hairline Stainless Steel"){
            setCarDoorPanelSteelSelected(true);
            setCarDoorPanelCoatedSelected(false);
            setCarDoorPanelPaintSelected(false);
            setCarDoorPanelOthersSelected(false);
        } else if (data == "Power Coated"){
            setCarDoorPanelCoatedSelected(true);
            setCarDoorPanelSteelSelected(false);
            setCarDoorPanelPaintSelected(false);
            setCarDoorPanelOthersSelected(false);
        } else if (data == "Spray Paint"){
            setCarDoorPanelPaintSelected(true);
            setCarDoorPanelSteelSelected(false);
            setCarDoorPanelCoatedSelected(false);
            setCarDoorPanelOthersSelected(false);
        } else if (data == "Others"){
            setCarDoorPanelOthersSelected(true);
            setCarDoorPanelSteelSelected(false);
            setCarDoorPanelCoatedSelected(false);
            setCarDoorPanelPaintSelected(false);
        }
    }
    
    const handleCarDoorGuard = (data) =>{
        if(data == "Hairline Stainless Steel"){
            setCarDoorGuardSteelSelected(true);
            setCarDoorGuardCoatedSelected(false);
            setCarDoorGuardPaintSelected(false);
            setCarDoorGuardOthersSelected(false);
        } else if (data == "Power Coated"){
            setCarDoorGuardCoatedSelected(true);
            setCarDoorGuardSteelSelected(false);
            setCarDoorGuardPaintSelected(false);
            setCarDoorGuardOthersSelected(false);
        } else if (data == "Spray Paint"){
            setCarDoorGuardPaintSelected(true);
            setCarDoorGuardSteelSelected(false);
            setCarDoorGuardCoatedSelected(false);
            setCarDoorGuardOthersSelected(false);
        } else if (data == "Others"){
            setCarDoorGuardOthersSelected(true);
            setCarDoorGuardSteelSelected(false);
            setCarDoorGuardCoatedSelected(false);
            setCarDoorGuardPaintSelected(false);
        }
    }
    const handleLandingDoorPanelType = (data) =>{
        if(data == "Solid Door" || data == "Big Vision Door" || data == "Frameless Door" || data == "Others"){
            setLandingDoorType(data);
            setLandingDoorTypeSelected(true);
        }
        else {
            setLandingDoorType(null);
            setLandingDoorTypeSelected(false);
        }
    }
    
    const handleShaft = (data) =>{
        if(data == "Galvanized"){
            setShaftGalvanizedSelected(true);
            setShaftSteelSelected(false);
            setShaftCoatedSelected(false);
            setShaftPaintSelected(false);
            setShaftOthersSelected(false);
        } else if(data == "Hairline Stainless Steel"){
            setShaftSteelSelected(true);
            setShaftGalvanizedSelected(false);
            setShaftCoatedSelected(false);
            setShaftPaintSelected(false);
            setShaftOthersSelected(false);
        } else if (data == "Power Coated"){
            setShaftCoatedSelected(true);
            setShaftGalvanizedSelected(false);
            setShaftSteelSelected(false);
            setShaftPaintSelected(false);
            setShaftOthersSelected(false);
        } else if (data == "Spray Paint"){
            setShaftPaintSelected(true);
            setShaftGalvanizedSelected(false);
            setShaftSteelSelected(false);
            setShaftCoatedSelected(false);
            setShaftOthersSelected(false);
        } else if (data == "Others"){
            setShaftOthersSelected(true);
            setShaftGalvanizedSelected(false);
            setShaftSteelSelected(false);
            setShaftCoatedSelected(false);
            setShaftPaintSelected(false);
        }
    }
    
    const handleLandingFrame = (data) =>{
        if(data == "Hairline Stainless Steel"){
            setLandingFrameSteelSelected(true);
            setLandingFrameCoatedSelected(false);
            setLandingFramePaintSelected(false);
            setLandingFrameFinishesSelected(false);
            setLandingFrameOthersSelected(false);
        } else if (data == "Power Coated"){
            setLandingFrameCoatedSelected(true);
            setLandingFrameSteelSelected(false);
            setLandingFramePaintSelected(false);
            setLandingFrameFinishesSelected(false);
            setLandingFrameOthersSelected(false);
        } else if (data == "Spray Paint"){
            setLandingFramePaintSelected(true);
            setLandingFrameSteelSelected(false);
            setLandingFrameCoatedSelected(false);
            setLandingFrameFinishesSelected(false);
            setLandingFrameOthersSelected(false);
        } else if (data == "Bare Finishes"){
            setLandingFrameFinishesSelected(true);
            setLandingFrameSteelSelected(false);
            setLandingFrameCoatedSelected(false);
            setLandingFramePaintSelected(false);
            setLandingFrameOthersSelected(false);
        } else if (data == "Others"){
            setLandingFrameOthersSelected(true);
            setLandingFrameSteelSelected(false);
            setLandingFrameCoatedSelected(false);
            setLandingFramePaintSelected(false);
            setLandingFrameFinishesSelected(false);
        }
    }
    
    const handleLandingPanel = (data) =>{
        if(data == "Hairline Stainless Steel"){
            setLandingPanelSteelSelected(true);
            setLandingPanelCoatedSelected(false);
            setLandingPanelPaintSelected(false);
            setLandingPanelOthersSelected(false);
        } else if (data == "Power Coated"){
            setLandingPanelCoatedSelected(true);
            setLandingPanelSteelSelected(false);
            setLandingPanelPaintSelected(false);
            setLandingPanelOthersSelected(false);
        } else if (data == "Spray Paint"){
            setLandingPanelPaintSelected(true);
            setLandingPanelSteelSelected(false);
            setLandingPanelCoatedSelected(false);
            setLandingPanelOthersSelected(false);
        } else if (data == "Others"){
            setLandingPanelOthersSelected(true);
            setLandingPanelSteelSelected(false);
            setLandingPanelCoatedSelected(false);
            setLandingPanelPaintSelected(false);
        }
    }
    
    const handleControllerBox = (data) =>{
        if(data == "Hairline Stainless Steel"){
            setControllerBoxSteelSelected(true);
            setControllerBoxCoatedSelected(false);
            setControllerBoxPaintSelected(false);
            setControllerBoxOthersSelected(false);
        } else if (data == "Power Coated"){
            setControllerBoxCoatedSelected(true);
            setControllerBoxSteelSelected(false);
            setControllerBoxPaintSelected(false);
            setControllerBoxOthersSelected(false);
        } else if (data == "Spray Paint"){
            setControllerBoxPaintSelected(true);
            setControllerBoxSteelSelected(false);
            setControllerBoxCoatedSelected(false);
            setControllerBoxOthersSelected(false);
        } else if (data == "Others"){
            setControllerBoxOthersSelected(true);
            setControllerBoxSteelSelected(false);
            setControllerBoxCoatedSelected(false);
            setControllerBoxPaintSelected(false);
        }
    }
    
    const handleCarOperatingPanel = (data) =>{
        if(data == "Hairline Stainless Steel"){
            setCarOperatingPanelSteelSelected(true);
            setCarOperatingPanelOthersSelected(false);
        } else if (data == "Others"){
            setCarOperatingPanelOthersSelected(true);
            setCarOperatingPanelSteelSelected(false);
        } else {
            setCarOperatingPanelOthersSelected(false);
            setCarOperatingPanelSteelSelected(false);
        }
    }
    
    const handleHallOperatingPanel = (data) =>{
        if(data == "Hairline Stainless Steel"){
            setHallOperatingPanelSteelSelected(true);
            setHallOperatingPanelOthersSelected(false);
        } else if (data == "Others"){
            setHallOperatingPanelOthersSelected(true);
            setHallOperatingPanelSteelSelected(false);
        } else {
            setHallOperatingPanelOthersSelected(false);
            setHallOperatingPanelSteelSelected(false);
        }
    }


    const onSubmit = (data) => {
        Notiflix.Confirm.show(
            'Confirm to next step',
            'Do you want to continue?',
            'Yes',
            'No',
            
            async () => {
                let dao = new ElevatorModelDao();
                if (isEditing) {
                data = {
                    ...data,
                    floorMaterial: watch("floorOwner") == "Niche" ? watch("floorMaterial") : "",
                    floorRemarks: watch("floorOwner") == "Niche" ? watch("floorRemarks") : "",
                    carDoorOthersApplicable: watch("carDoorPanelType") == "Others" ? (watch("carDoorOthersApplicable") === 'true' ? true : false) : false,
                    
                    wallPanelOthers: watch("wallPanelMaterial") == "Others" ? watch("wallPanelOthers") : "",
                    ceilingOthers: watch("ceilingMaterial") == "Others" ? watch("ceilingOthers") : "",
                    carDoorFrontOthers: watch("carDoorFrontMaterial") == "Others" ? watch("carDoorFrontOthers") : "",
                    carDoorPanelOthers: watch("carDoorPanelMaterial") == "Others" ? watch("carDoorPanelOthers") : "",
                    carDoorGuardOthers: watch("carDoorGuardMaterial") == "Others" ? watch("carDoorGuardOthers") : "",

                    carOperatingPanelSpec: watch("carOperatingPanelMaterial") == "Device's Default" ? "" : watch("carOperatingPanelSpec"),
                    carOperatingPanelOthers: carOperatingPanelOthersSelected ? watch("carOperatingPanelOthers") : "",
                    hallOperatingPanelSpec: watch("hallOperatingPanelMaterial") == "Device's Default" ? "" : watch("hallOperatingPanelSpec"),
                    hallOperatingPanelOthers: hallOperatingPanelOthersSelected ? watch("hallOperatingPanelOthers") : "",
                    shaftOthers: watch("shaftMaterial") == "Others" ? watch("shaftOthers") : "",
                    landingFrameOthers: watch("landingFrameMaterial") == "Others" ? watch("landingFrameOthers") : "",
                    landingPanelOthers: watch("landingPanelMaterial") == "Others" ? watch("landingPanelOthers") : "",
                    controllerBoxOthers: watch("controllerBoxMaterial") == "Others" ? watch("controllerBoxOthers") : "",

                    id: state.details.id,
                }      
                console.log(data) 
                await dao.updateElevatorModelStep2(data, token).then(responseJson => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        Report.success('Success', 'Successfully updated model step 2.', 'Okay', handleNext());
                    } else {
                        Report.failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                    }
                })
                }else {
                    data = {
                        ...data,
                        floorMaterial: watch("floorOwner") == "Niche" ? watch("floorMaterial") : "",
                        floorRemarks: watch("floorOwner") == "Niche" ? watch("floorRemarks") : "",
                        carDoorOthersApplicable: watch("carDoorPanelType") == "Others" ? (watch("carDoorOthersApplicable") === 'true' ? true : false) : false,
                        
                        wallPanelOthers: watch("wallPanelMaterial") == "Others" ? watch("wallPanelOthers") : "",
                        ceilingOthers: watch("ceilingMaterial") == "Others" ? watch("ceilingOthers") : "",
                        carDoorFrontOthers: watch("carDoorFrontMaterial") == "Others" ? watch("carDoorFrontOthers") : "",
                        carDoorPanelOthers: watch("carDoorPanelMaterial") == "Others" ? watch("carDoorPanelOthers") : "",
                        carDoorGuardOthers: watch("carDoorGuardMaterial") == "Others" ? watch("carDoorGuardOthers") : "",

                        
                        carOperatingPanelSpec: watch("carOperatingPanelMaterial") == "Device's Default" ? "" : watch("carOperatingPanelSpec"),
                        carOperatingPanelOthers: carOperatingPanelOthersSelected ? watch("carOperatingPanelOthers") : "",
                        hallOperatingPanelSpec: watch("hallOperatingPanelMaterial") == "Device's Default" ? "" : watch("hallOperatingPanelSpec"),
                        hallOperatingPanelOthers: hallOperatingPanelOthersSelected ? watch("hallOperatingPanelOthers") : "",
                        shaftOthers: watch("shaftMaterial") == "Others" ? watch("shaftOthers") : "",
                        landingFrameOthers: watch("landingFrameMaterial") == "Others" ? watch("landingFrameOthers") : "",
                        landingPanelOthers: watch("landingPanelMaterial") == "Others" ? watch("landingPanelOthers") : "",
                        controllerBoxOthers: watch("controllerBoxMaterial") == "Others" ? watch("controllerBoxOthers") : "",

                        id: elevatorId,
                    }  
                    console.log(data);
                    await dao.createElevatorModelStep2(data, token).then((responseJson) => {
                        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                          let model = responseJson[ApiKey._API_DATA_KEY];
                          Notiflix.Report.init({ plainText: false });
                          Notiflix.Report.success(
                            "Success",
                            `Successfully created model step 2.`,
                            "Okay",
                            handleNext()
                          );setStep2(data);
                        } else {
                          Notiflix.Report.failure(
                            "Error",
                            responseJson[ApiKey._API_MESSAGE_KEY]
                          );
                        }
                    });
                }
            }
        )
    }
    useEffect(() => {
        if (state?.details) {
            console.log(state)
            console.log(step2)
            reset(state?.details);
            
            handleWallPanelSide(state?.details?.wall_panel_side);
            handleCarWallPanel(state?.details?.wall_panel_material);
            handleCarCeiling(state?.details?.ceiling_material);
            handlePersonSelected(state?.details?.floor_owner);
            handleMaterialSelected(state?.details?.floor_material);
            handleCarDoorFront(state?.details?.car_door_front_material);
            handleCarDoorPanelType(state?.details?.car_door_panel_type);
            handleCarDoorPanel(state?.details?.car_door_panel_material);
            handleCarDoorGuard(state?.details?.car_door_guard_material);
            
            handleShaft(state?.details?.shaft_material);
            handleLandingFrame(state?.details?.landing_frame_material);
            handleLandingDoorPanelType(state?.details?.landing_panel_type);
            handleLandingPanel(state?.details?.landing_panel_material);
            handleControllerBox(state?.details?.controller_box_material);
            handleCarOperatingPanel(state?.details?.car_operating_panel_material);
            handleHallOperatingPanel(state?.details?.hall_operating_panel_material);
        }

      document.title = `NECS | ${isEditing ? 'Update' : 'Create'} Elevator Model`;
    }, [reset, isEditing, state])

    useEffect(() => {
        if(step2 != null) {
            handleWallPanelSide(step2?.wallPanelSide);
            handleCarWallPanel(step2?.wallPanelMaterial);
            handleCarCeiling(step2?.ceilingMaterial);
            handlePersonSelected(step2?.floorOwner);
            handleMaterialSelected(step2?.floorMaterial);
            handleCarDoorFront(step2?.carDoorFrontMaterial);
            handleCarDoorPanelType(step2?.carDoorPanelType);
            handleCarDoorPanel(step2?.carDoorPanelMaterial);
            handleCarDoorGuard(step2?.carDoorGuardMaterial);
            
            handleShaft(step2?.shaftMaterial);
            handleLandingFrame(step2?.landingFrameMaterial);
            handleLandingDoorPanelType(step2?.landingPanelType);
            handleLandingPanel(step2?.landingPanelMaterial);
            handleControllerBox(step2?.controllerBoxMaterial);
            handleCarOperatingPanel(step2?.carOperatingPanelMaterial);
            handleHallOperatingPanel(step2?.hallOperatingPanelMaterial);
        }              
    }, [step2]);
    return(
    <>
    <form id="elevatorModelStep2" onSubmit={handleSubmit(onSubmit)}>
    <Row style={{backgroundColor:"#F5F5F5", margin: "10px"}}>
        <Col md={12}>
            <h1 className="page-subheader" style={{borderBottom: "2px solid #e4e6e8", marginLeft:"20px"}}>
                Lift Finishes Info
            </h1>
        </Col>
        <Col md={6}>
        <Row style={{margin: "0px 10px"}}>
            <Col md={12}>
            <h2 className="page-subheader">
                Car Wall Panel
            </h2>
        </Col>
        <Col md={12}>
            <CustomSelect
                control={control}
                name="wallPanelSide"
                label="Car Wall Panel"
                placeholder="Choose the side of car wall panel"
                options={WallPanelSide}
                defaultValue={state?.details ? state.details.wall_panel_side : step2?.wallPanelSide}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                rules={{
                    required: true,
                }}
                onChange={handleWallPanelSide}
            />
        </Col>
        </Row>
        {panelSideSelected && (
                // <form id="wallPanelSideDetails">
                    <Row style={{margin: "0px 10px 10px 10px"}}>
                        
                        <Col md={12}>
                            <CustomSelect
                                  control={control}
                                  name="wallPanelMaterial"
                                  label="Car Wall Panel Material"
                                  placeholder="Choose the material"
                                  options={SevenTypesOfMaterial}
                                  defaultValue={state?.details ? state.details.wall_panel_material : step2?.wallPanelMaterial}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                                  rules={{
                                      required: true,
                                  }}
                                  onChange={handleCarWallPanel}
                                />
                        </Col>
                        {carWallPanelSteelSelected && (
                            <Col md={12}>
                                <CustomSelect
                                      control={control}
                                      name="wallPanelSpec"
                                      label="Hairline Stainless Steel"
                                      placeholder="Choose the hairline stainless steel"
                                      options={HairlineStainlessSteel}
                                      defaultValue={state?.details ? state.details.wall_panel_spec : step2?.wallPanelSpec}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                                      rules={{
                                          required: true,
                                      }}
                                    />
                            </Col>
                        )}
                        {carWallPanelCoatedSelected &&(
                            <Col md={12}>
                                <CustomSelect
                                      control={control}
                                      name="wallPanelSpec"
                                      label="Powder Coated"
                                      placeholder="Choose the powder coated"
                                      options={PowderCoated}
                                      defaultValue={state?.details ? state.details.wall_panel_spec : step2?.wallPanelSpec}
                                      rules={{
                                          required: true,
                                      }}
                                    />
                            </Col>
                        )}
                        {carWallPanelPaintSelected && (
                            <Col md={12}>
                                <CustomSelect
                                      control={control}
                                      name="wallPanelSpec"
                                      label="Spray Paint"
                                      placeholder="Choose the spray paint"
                                      options={SprayPaint}
                                      defaultValue={state?.details ? state.details.wall_panel_spec : step2?.wallPanelSpec}
                                      rules={{
                                          required: true,
                                      }}
                                    />
                            </Col>
                        )}
                        {carWallPanelGlassSelected && (
                            <Col md={12}>
                                <CustomSelect
                                      control={control}
                                      name="wallPanelSpec"
                                      label="Glass"
                                      placeholder="Choose the glass"
                                      options={Glass}
                                      defaultValue={state?.details ? state.details.wall_panel_spec : step2?.wallPanelSpec}
                                      rules={{
                                          required: true,
                                      }}
                                    />
                            </Col>
                        )}
                        {carWallPanelMirrorSelected && (
                            <Col md={12}>
                                <CustomSelect
                                      control={control}
                                      name="wallPanelSpec"
                                      label="Mirror"
                                      placeholder="Choose the mirror"
                                      options={Mirror}
                                      defaultValue={state?.details ? state.details.wall_panel_spec : step2?.wallPanelSpec}
                                      rules={{
                                          required: true,
                                      }}
                                    />
                            </Col>
                        )}
                        {carWallPanelFinishesSelected && (
                            <Col md={12}>
                                <CustomSelect
                                      control={control}
                                      name="wallPanelSpec"
                                      label="Bare Finishes"
                                      placeholder="Choose the bare finishes"
                                      options={BareFinishes}
                                      defaultValue={state?.details ? state.details.wall_panel_spec : step2?.wallPanelSpec}
                                      rules={{
                                          required: true,
                                      }}
                                    />
                            </Col>
                        )}
                        {carWallPanelOthersSelected && (
                            <Col md={12}>
                                    <CustomInput
                                      {...register("wallPanelOthers", {
                                        required: false,
                                      })}
                                      name="wallPanelOthers"
                                      label="Others"
                                      defaultValue={state?.details ? state.details.wall_panel_others : step2?.wallPanelOthers}
                                    />
                            </Col>
                        )}
                    </Row>
            
        )}
        </Col>{/*end of car wall panel*/}

        <Col md={6}>
            <Row style={{backgroundColor:"#F5F5F5", margin: "0px 10px 10px 10px"}}>
                <Col md={12}>
                  <h2 className="page-subheader">
                      Car Ceiling
                  </h2>
                </Col>
                
                <Col md={12}>
                    <CustomSelect
                          control={control}
                          name="ceilingMaterial"
                          label="Car Ceiling Material"
                          placeholder="Choose the material"
                          options={SevenTypesOfMaterial}
                          defaultValue={state?.details ? state.details.ceiling_material : step2?.ceilingMaterial}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                          rules={{
                              required: true,
                          }}
                          onChange={handleCarCeiling}
                        />
                </Col>
                {carCeilingSteelSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="ceilingSpec"
                              label="Hairline Stainless Steel"
                              placeholder="Choose the hairline stainless steel"
                              options={HairlineStainlessSteel}
                              defaultValue={state?.details ? state.details.ceiling_spec : step2?.ceilingSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carCeilingCoatedSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="ceilingSpec"
                              label="Powder Coated"
                              placeholder="Choose the powder coated"
                              options={PowderCoated}
                              defaultValue={state?.details ? state.details.ceiling_spec : step2?.ceilingSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carCeilingPaintSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="ceilingSpec"
                              label="Spray Paint"
                              placeholder="Choose the spray paint"
                              options={SprayPaint}
                              defaultValue={state?.details ? state.details.ceiling_spec : step2?.ceilingSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carCeilingGlassSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="ceilingSpec"
                              label="Glass"
                              placeholder="Choose the glass"
                              options={Glass}
                              defaultValue={state?.details ? state.details.ceiling_spec : step2?.ceilingSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carCeilingMirrorSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="ceilingSpec"
                              label="Mirror"
                              placeholder="Choose the mirror"
                              options={Mirror}
                              defaultValue={state?.details ? state.details.ceiling_spec : step2?.ceilingSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carCeilingFinishesSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="ceilingSpec"
                              label="Bare Finishes"
                              placeholder="Choose the bare finishes"
                              options={BareFinishes}
                              defaultValue={state?.details ? state.details.ceiling_spec : step2?.ceilingSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carCeilingOthersSelected && (
                    <Col md={12}>
                            <CustomInput
                              {...register("ceilingOthers", {
                                required: false,
                              })}
                              name="ceilingOthers"
                              label="Others"
                              defaultValue={state?.details ? state.details.ceiling_others : step2?.ceilingOthers}
                            />
                    </Col>
                )}
                
            </Row>
            </Col>{/*end of car ceiling*/}
        {/*when on the car floor there use the command onChange={function-> used to set a state}
            then set a condition {what && {content to display the breakdown form inside}} */}
            
        <Col md={6}>
            <Row style={{backgroundColor:"#F5F5F5", margin: "0px 10px 10px 10px"}}>
                <Col md={12}>
                  <h2 className="page-subheader">
                      Car Floor
                  </h2>
                </Col>
                <Col md={12}>
                    <CustomSelect
                          control={control}
                          name="floorOwner"
                          label="Floor By Who"
                          placeholder="Please select"
                          options={Floor}
                          defaultValue={state?.details ? state.details.floor_owner : step2?.floorOwner}
                          rules={{
                              required: true,
                          }}
                          onChange={handlePersonSelected}
                        />
                </Col>
                {personSelected && (
                    <Col md={12}>
                    <CustomSelect
                      control={control}
                      name="floorMaterial"
                      label="Material"
                      placeholder="Please select"
                      options={Material}
                      defaultValue={state?.details ? state.details.floor_material : step2?.floorMaterial}
                      rules={{
                          required: true,
                      }}
                      onChange={handleMaterialSelected}
                  />
                  </Col>
                )}
                    {materialSelected && (
                        <Col md={12}>
                            <CustomInput
                                {...register("floorRemarks", {
                                  required: false,
                                })}
                                name="floorRemarks"
                                label="Floor Remarks"
                                defaultValue={state?.details ? state.details.floor_remarks : step2?.floorRemarks}
                            />
                        </Col>
                    )}
            </Row>
        </Col>{/*end of car floor*/}
        <Col md={6}>
            <Row style={{backgroundColor:"#F5F5F5", margin: "0px 10px 10px 10px"}}>
                <Col md={12}>
                  <h2 className="page-subheader">
                      Car Front Return
                  </h2>
                </Col>
                <Col md={12}>
                    <CustomSelect
                          control={control}
                          name="carDoorFrontMaterial"
                          label="Car Door Front Material"
                          placeholder="Choose the material"
                          options={SevenTypesOfMaterial}
                          defaultValue={state?.details ? state.details.car_door_front_material : step2?.carDoorFrontMaterial}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                          rules={{
                              required: true,
                          }}
                          onChange={handleCarDoorFront}
                        />
                </Col>
                {carDoorFrontSteelSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="carDoorFrontSpec"
                              label="Hairline Stainless Steel"
                              placeholder="Choose the hairline stainless steel"
                              options={HairlineStainlessSteel}
                              defaultValue={state?.details ? state.details.car_door_front_spec : step2?.carDoorFrontSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carDoorFrontCoatedSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="carDoorFrontSpec"
                              label="Powder Coated"
                              placeholder="Choose the powder coated"
                              options={PowderCoated}
                              defaultValue={state?.details ? state.details.car_door_front_spec : step2?.carDoorFrontSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carDoorFrontPaintSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="carDoorFrontSpec"
                              label="Spray Paint"
                              placeholder="Choose the spray paint"
                              options={SprayPaint}
                              defaultValue={state?.details ? state.details.car_door_front_spec : step2?.carDoorFrontSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carDoorFrontGlassSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="carDoorFrontSpec"
                              label="Glass"
                              placeholder="Choose the glass"
                              options={Glass}
                              defaultValue={state?.details ? state.details.car_door_front_spec : step2?.carDoorFrontSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carDoorFrontMirrorSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="carDoorFrontSpec"
                              label="Mirror"
                              placeholder="Choose the mirror"
                              options={Mirror}
                              defaultValue={state?.details ? state.details.car_door_front_spec : step2?.carDoorFrontSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carDoorFrontFinishesSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="carDoorFrontSpec"
                              label="Bare Finishes"
                              placeholder="Choose the bare finishes"
                              options={BareFinishes}
                              defaultValue={state?.details ? state.details.car_door_front_spec : step2?.carDoorFrontSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carDoorFrontOthersSelected && (
                    <Col md={12}>
                            <CustomInput
                              {...register("carDoorFrontOthers", {
                                required: false,
                              })}
                              name="carDoorFrontOthers"
                              label="Others"
                              defaultValue={state?.details ? state.details.car_door_front_others : step2?.carDoorFrontOthers}
                            />
                    </Col>
                )}

            </Row>
        </Col>{/*end of car front return*/}
        <Col md={6}>
        <h2 className="page-subheader" style={{marginLeft:"20px"}}>
            Car Door Panel
        </h2>
        <Row style={{margin: "0px 10px"}}>
        <Col md={12}>
            <CustomSelect
                control={control}
                name="carDoorPanelType"
                label="Car Wall Panel"
                placeholder="Choose the side of car wall panel"
                options={DoorPanelTypes}
                defaultValue={state?.details ? state.details.car_door_panel_type : step2?.carDoorPanelType}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                rules={{
                    required: true,
                }}
                onChange={handleCarDoorPanelType}
            />
        </Col>
        </Row>
        {carDoorTypeSelected && (
            <Row style={{margin: "0px 10px 10px 10px"}}>
                {(carDoorType=="Others") && (
                    <Col md={12}>
                        <label className="custom-input-label">Applicable?</label>
                            <Checkbox 
                              {...register("carDoorOthersApplicable", {
                                required: false,
                              })}
                                name="carDoorOthersApplicable"
                                options={YesOrNo}
                                defaultValue={state?.details ? state.details.car_door_others_applicable == 1? true : false : step2?.carDoorOthersApplicable === true ? true : false}
                            />
                    </Col>
                )}
                <Col md={12}>
                    <CustomSelect
                          control={control}
                          name="carDoorPanelMaterial"
                          label="Car Door Panel Material"
                          placeholder="Choose the material"
                          options={FourTypesOfMaterial}
                          defaultValue={state?.details ? state.details.car_door_panel_material : step2?.carDoorPanelMaterial}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                          rules={{
                              required: true,
                          }}
                          onChange={handleCarDoorPanel}
                        />
                </Col>
                {carDoorPanelSteelSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="carDoorPanelSpec"
                              label="Hairline Stainless Steel"
                              placeholder="Choose the hairline stainless steel"
                              options={HairlineStainlessSteel}
                              defaultValue={state?.details ? state.details.car_door_panel_spec : step2?.carDoorPanelSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carDoorPanelCoatedSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="carDoorPanelSpec"
                              label="Powder Coated"
                              placeholder="Choose the powder coated"
                              options={PowderCoated}
                              defaultValue={state?.details ? state.details.car_door_panel_spec : step2?.carDoorPanelSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carDoorPanelPaintSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="carDoorPanelSpec"
                              label="Spray Paint"
                              placeholder="Choose the spray paint"
                              options={SprayPaint}
                              defaultValue={state?.details ? state.details.car_door_panel_spec : step2?.carDoorPanelSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carDoorPanelOthersSelected && (
                    <Col md={12}>
                            <CustomInput
                              {...register("carDoorPanelOthers", {
                                required: false,
                              })}
                              name="carDoorPanelOthers"
                              label="Others"
                              defaultValue={state?.details ? state.details.car_door_panel_others : step2?.carDoorPanelOthers}
                            />
                    </Col>
                )}

            </Row>
        )}
        </Col>{/*end of car door panel*/}

        <Col md={6}>
            <Row style={{backgroundColor:"#F5F5F5", margin: "0px 10px 10px 10px"}}>
                <Col md={12}>
                  <h2 className="page-subheader">
                      Car Toe Guard
                  </h2>
                </Col>
                <Col md={12}>
                    <CustomSelect
                          control={control}
                          name="carDoorGuardMaterial"
                          label="Car Door Guard Material"
                          placeholder="Choose the material"
                          options={FourTypesOfMaterial}
                          defaultValue={state?.details ? state.details.car_door_guard_material : step2?.carDoorGuardMaterial}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                          rules={{
                              required: true,
                          }}
                          onChange={handleCarDoorGuard}
                        />
                </Col>
                {carDoorGuardSteelSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="carDoorGuardSpec"
                              label="Hairline Stainless Steel"
                              placeholder="Choose the hairline stainless steel"
                              options={HairlineStainlessSteel}
                              defaultValue={state?.details ? state.details.car_door_guard_spec : step2?.carDoorGuardSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carDoorGuardCoatedSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="carDoorGuardSpec"
                              label="Powder Coated"
                              placeholder="Choose the powder coated"
                              options={PowderCoated}
                              defaultValue={state?.details ? state.details.car_door_guard_spec : step2?.carDoorGuardSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carDoorGuardPaintSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="carDoorGuardSpec"
                              label="Spray Paint"
                              placeholder="Choose the spray paint"
                              options={SprayPaint}
                              defaultValue={state?.details ? state.details.car_door_guard_spec : step2?.carDoorGuardSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carDoorGuardOthersSelected && (
                    <Col md={12}>
                            <CustomInput
                              {...register("carDoorGuardOthers", {
                                required: false,
                              })}
                              name="carDoorGuardOthers"
                              label="Others"
                              defaultValue={state?.details ? state.details.car_door_guard_others : step2?.carDoorGuardOthers}
                            />
                    </Col>
                )}

            </Row>
        </Col>{/*end of car toe guard*/}

        <Col md={6}>
            <Row style={{backgroundColor:"#F5F5F5", margin: "10px"}}>
                <Col md={12}>
                  <h2 className="page-subheader">
                      Lift Shaft Facial Plate
                  </h2>
                </Col>
                <Col md={12}>
                    <CustomSelect
                          control={control}
                          name="shaftMaterial"
                          label="Lift Shaft Material"
                          placeholder="Choose the material"
                          options={LiftShaftMaterial}
                          defaultValue={state?.details ? state.details.shaft_material : step2?.shaftMaterial}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                          rules={{
                              required: true,
                          }}
                          onChange={handleShaft}
                        />
                </Col>
                {shaftGalvanizedSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="shaftSpec"
                              label="Hairline Stainless Galvanized"
                              placeholder="Please select"
                              options={Galvanized}
                              defaultValue={state?.details ? state.details.shaft_spec : step2?.shaftSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {shaftSteelSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="shaftSpec"
                              label="Hairline Stainless Steel"
                              placeholder="Choose the hairline stainless steel"
                              options={HairlineStainlessSteel}
                              defaultValue={state?.details ? state.details.shaft_spec : step2?.shaftSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {shaftCoatedSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="shaftSpec"
                              label="Powder Coated"
                              placeholder="Choose the powder coated"
                              options={PowderCoated}
                              defaultValue={state?.details ? state.details.shaft_spec : step2?.shaftSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {shaftPaintSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="shaftSpec"
                              label="Spray Paint"
                              placeholder="Choose the spray paint"
                              options={SprayPaint}
                              defaultValue={state?.details ? state.details.shaft_spec : step2?.shaftSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {shaftOthersSelected && (
                    <Col md={12}>
                        {/* <label className="custom-input-label">Others</label> */}
                            <CustomInput
                              {...register("shaftOthers", {
                                required: false,
                              })}
                              name="shaftOthers"
                              label="Others"
                              defaultValue={state?.details ? state.details.shaft_others : step2?.shaftOthers}
                            />
                    </Col>
                )}

            </Row>
        </Col>{/*end of lift shaft facial plate*/}
        <Col md={6}>
            <Row style={{backgroundColor:"#F5F5F5", margin: "10px 10px 0px 10px"}}>
                <Col md={12}>
                  <h2 className="page-subheader">
                      Landing Door Frame
                  </h2>
                </Col>
                <Col md={12}>
                    <CustomSelect
                          control={control}
                          name="landingFrameMaterial"
                          label="Lift Shaft Material"
                          placeholder="Choose the material"
                          options={LandingDoorFrameMaterial}
                          defaultValue={state?.details ? state.details.landing_frame_material : step2?.landingFrameMaterial}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                          rules={{
                              required: true,
                          }}
                          onChange={handleLandingFrame}
                        />
                </Col>
                {landingFrameSteelSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="landingFrameSpec"
                              label="Hairline Stainless Steel"
                              placeholder="Choose the hairline stainless steel"
                              options={HairlineStainlessSteel}
                              defaultValue={state?.details ? state.details.landing_frame_spec : step2?.landingFrameSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {landingFrameCoatedSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="landingFrameSpec"
                              label="Powder Coated"
                              placeholder="Choose the powder coated"
                              options={PowderCoated}
                              defaultValue={state?.details ? state.details.landing_frame_spec : step2?.landingFrameSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {landingFramePaintSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="landingFrameSpec"
                              label="Spray Paint"
                              placeholder="Choose the spray paint"
                              options={SprayPaint}
                              defaultValue={state?.details ? state.details.landing_frame_spec : step2?.landingFrameSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {landingFrameFinishesSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="landingFrameSpec"
                              label="Bare Finishes"
                              placeholder="Choose the bare finishes"
                              options={BareFinishes}
                              defaultValue={state?.details ? state.details.landing_frame_spec : step2?.landingFrameSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {landingFrameOthersSelected && (
                    <Col md={12}>
                            <CustomInput
                              {...register("landingFrameOthers", {
                                required: false,
                              })}
                              name="landingFrameOthers"
                              label="Others"
                              defaultValue={state?.details ? state.details.landing_frame_others : step2?.landingFrameOthers}
                            />
                    </Col>
                )}

            </Row>
        </Col>{/*end of landing door frame*/}

        <Col md={6}>
            <h2 className="page-subheader" style={{marginLeft:"20px"}}>
                Landing Door Panel
            </h2>
                <Row style={{margin: "0px 10px"}}>
                    <Col md={12}>
                        <CustomSelect
                            control={control}
                            name="landingPanelType"
                            label="Car Wall Panel"
                            placeholder="Choose the side of car wall panel"
                            options={DoorPanelTypes}
                            defaultValue={state?.details ? state.details.landing_panel_type : step2?.landingPanelType}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                            rules={{
                                required: true,
                            }}
                            onChange={handleLandingDoorPanelType}
                        />
                    </Col>
                </Row>
                {landingDoorTypeSelected && (
                    <Row style={{margin: "0px 10px 10px 10px"}}>
                        <Col md={12}>
                            <CustomSelect
                                  control={control}
                                  name="landingPanelMaterial"
                                  label="Landing Door Panel Material"
                                  placeholder="Choose the material"
                                  options={FourTypesOfMaterial}
                                  defaultValue={state?.details ? state.details.landing_panel_material : step2?.landingPanelMaterial}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                                  rules={{
                                      required: true,
                                  }}
                                  onChange={handleLandingPanel}
                                />
                        </Col>
                        {landingPanelSteelSelected && (
                            <Col md={12}>
                                <CustomSelect
                                      control={control}
                                      name="landingPanelSpec"
                                      label="Hairline Stainless Steel"
                                      placeholder="Choose the hairline stainless steel"
                                      options={HairlineStainlessSteel}
                                      defaultValue={state?.details ? state.details.landing_panel_spec : step2?.landingPanelSpec}
                                      rules={{
                                          required: true,
                                      }}
                                    />
                            </Col>
                        )}
                        {landingPanelCoatedSelected && (
                            <Col md={12}>
                                <CustomSelect
                                      control={control}
                                      name="landingPanelSpec"
                                      label="Powder Coated"
                                      placeholder="Choose the powder coated"
                                      options={PowderCoated}
                                      defaultValue={state?.details ? state.details.landing_panel_spec : step2?.landingPanelSpec}
                                      rules={{
                                          required: true,
                                      }}
                                    />
                            </Col>
                        )}
                        {landingPanelPaintSelected && (
                            <Col md={12}>
                                <CustomSelect
                                      control={control}
                                      name="landingPanelSpec"
                                      label="Spray Paint"
                                      placeholder="Choose the spray paint"
                                      options={SprayPaint}
                                      defaultValue={state?.details ? state.details.landing_panel_spec : step2?.landingPanelSpec}
                                      rules={{
                                          required: true,
                                      }}
                                    />
                            </Col>
                        )}
                        {landingPanelOthersSelected && (
                            <Col md={12}>
                                    <CustomInput
                                      {...register("landingPanelOthers", {
                                        required: false,
                                      })}
                                      name="landingPanelOthers"
                                      label="Others"
                                      defaultValue={state?.details ? state.details.landing_pane_others : step2?.landingPanelOthers}
                                    />
                            </Col>
                        )}

                </Row>
            )}
            
        </Col>{/*end of landing door panel*/}

        <Col md={6}>
            <Row style={{backgroundColor:"#F5F5F5", margin: "0px 10px 10px 10px"}}>
                <Col md={12}>
                  <h2 className="page-subheader">
                      Controller Box
                  </h2>
                </Col>
                <Col md={12}>
                    <CustomSelect
                        control={control}
                        name="controllerBoxMaterial"
                        label="Lift Shaft Material"
                        placeholder="Choose the material"
                        options={FourTypesOfMaterial}
                        defaultValue={state?.details ? state.details.controller_box_material : step2?.controllerBoxMaterial}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                        rules={{
                            required: true,
                        }}
                        onChange={handleControllerBox}
                    />
                </Col>
                {controllerBoxSteelSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="controllerBoxSpec"
                              label="Hairline Stainless Steel"
                              placeholder="Choose the hairline stainless steel"
                              options={HairlineStainlessSteel}
                              defaultValue={state?.details ? state.details.controller_box_spec : step2?.controllerBoxSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {controllerBoxCoatedSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="controllerBoxSpec"
                              label="Powder Coated"
                              placeholder="Choose the powder coated"
                              options={PowderCoated}
                              defaultValue={state?.details ? state.details.controller_box_spec : step2?.controllerBoxSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {controllerBoxPaintSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="controllerBoxSpec"
                              label="Spray Paint"
                              placeholder="Choose the spray paint"
                              options={SprayPaint}
                              defaultValue={state?.details ? state.details.controller_box_spec : step2?.controllerBoxSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {controllerBoxOthersSelected && (
                    <Col md={12}>
                            <CustomInput
                              {...register("controllerBoxOthers", {
                                required: false,
                              })}
                              name="controllerBoxOthers"
                              label="Others"
                              defaultValue={state?.details ? state.details.controller_box_others : step2?.controllerBoxOthers}
                            />
                    </Col>
                )}
            </Row>
        </Col>{/*end of controller box*/}
        
        <Col md={6}>
            <Row style={{backgroundColor:"#F5F5F5", margin: "10px"}}>
                <Col md={12}>
                  <h2 className="page-subheader">
                      Car Operating Panel
                  </h2>
                </Col>
                <Col md={12}>
                    <CustomSelect
                          control={control}
                          name="carOperatingPanelMaterial"
                          label="Car Operating Panel Material"
                          placeholder="Choose the material"
                          options={ThreeTypesOfMaterial}
                          defaultValue={state?.details ? state.details.car_operating_panel_material : step2?.carOperatingPanelMaterial}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                          rules={{
                              required: true,
                          }}
                          onChange={handleCarOperatingPanel}
                        />
                </Col>
                {carOperatingPanelSteelSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="carOperatingPanelSpec"
                              label="Hairline Stainless Steel"
                              placeholder="Choose the hairline stainless steel"
                              options={HairlineStainlessSteel}
                              defaultValue={state?.details ? state.details.car_operating_panel_spec : step2?.carOperatingPanelSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {carOperatingPanelOthersSelected && (
                    <Col md={12}>
                            <CustomInput
                              {...register("carOperatingPanelOthers", {
                                required: false,
                              })}
                              name="carOperatingPanelOthers"
                              label="Others"
                              defaultValue={state?.details ? state.details.car_operating_panel_others : step2?.carOperatingPanelOthers}
                            />
                    </Col>
                )}
            </Row>
        </Col>{/*end of car operating panel*/}

        <Col md={6}>
            <Row style={{backgroundColor:"#F5F5F5", margin: "10px"}}>
                <Col md={12}>
                  <h2 className="page-subheader">
                      Hall Operating Panel
                  </h2>
                </Col>
                <Col md={12}>
                    <CustomSelect
                          control={control}
                          name="hallOperatingPanelMaterial"
                          label="Hall Operating Panel Material"
                          placeholder="Choose the material"
                          options={ThreeTypesOfMaterial}
                          defaultValue={state?.details ? state.details.hall_operating_panel_material : step2?.hallOperatingPanelMaterial}// make 4 state for each step then use this condition to set the default value when they go back (set value on success submit)
                          rules={{
                              required: true,
                          }}
                          onChange={handleHallOperatingPanel}
                        />
                </Col>
                {hallOperatingPanelSteelSelected && (
                    <Col md={12}>
                        <CustomSelect
                              control={control}
                              name="hallOperatingPanelSpec"
                              label="Hairline Stainless Steel"
                              placeholder="Choose the hairline stainless steel"
                              options={HairlineStainlessSteel}
                              defaultValue={state?.details ? state.details.hall_operating_panel_spec : step2?.hallOperatingPanelSpec}
                              rules={{
                                  required: true,
                              }}
                            />
                    </Col>
                )}
                {hallOperatingPanelOthersSelected && (
                    <Col md={12}>
                            <CustomInput
                              {...register("hallOperatingPanelOthers", {
                                required: false,
                              })}
                              name="hallOperatingPanelOthers"
                              label="Others"
                              defaultValue={state?.details ? state.details.hall_operating_panel_others : step2?.hallOperatingPanelOthers}
                            />
                    </Col>
                )}
            </Row>
        </Col>{/*end of car operating panel*/}
        
        <Col md={12}>
                <br />
                <button type="button" className='btn btn-default d-flex-center' style={{color:"#f64e60" , borderColor:"#f64e60", justifyContent:"center", margin:"auto", width:"100%"}} onClick={handleBack}>Back</button>
                <br />
                {isEmpty(state?.details?.deleted_at) && (<button type="submit" className="btn btn-themed btn-block">Continue</button>)}
                {(state?.details?.deleted_at) && (<button className="btn btn-themed btn-block" onClick={handleNext}>Next</button>)}
                <br />
        </Col>
      
    </Row></form>

    </>)
} 

export default WallPanelCeilingFloor;