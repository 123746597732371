import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';
import { isEmpty } from "lodash";
import { stringIsNullOrEmpty } from "util/Utility";

/// <summary>
/// Author: Christopher Chan
/// </summary>
class ElevatorModelDao extends DataAccessObject {

    async getElevatorModels(token, queryParams, trashed) {
        let url = ApiUrl._API_ELEVATOR_MODELS;

        if (!isEmpty(queryParams)) {
          let queryStr = new URLSearchParams(queryParams).toString();
          if (!stringIsNullOrEmpty(queryStr)) {
            url += `?${queryStr}`;//setquery before the trashed statement
          }
        }
        return this.get(url+ "&trashed="+trashed, token);
        //return this.get(ApiUrl._API_ELEVATOR_MODELS + "?trashed="+ trashed, token);    
    }
//open a async function to call the route from constant(constant also need to declare new route for it)
//refer to this one below
    async createElevatorModel(data, token) {
        return this.post(ApiUrl._API_CREATE_ELEVATOR_MODELS, data, token);
    }
    async createElevatorModelStep2(data, token) {
        return this.post(ApiUrl._API_CREATE_ELEVATOR_MODELS + "/step2", data, token);
    }
    async createElevatorModelStep3(data, token) {
        return this.post(ApiUrl._API_CREATE_ELEVATOR_MODELS + "/step3", data, token);
    }
    async createElevatorModelStep4(data, token) {
        return this.post(ApiUrl._API_CREATE_ELEVATOR_MODELS + "/step4", data, token);
    }

    async updateElevatorModel(data, token) {
        return this.post(ApiUrl._API_UPDATE_ELEVATOR_MODELS, data, token);
    }
    async updateElevatorModelStep2(data, token) {
        return this.post(ApiUrl._API_UPDATE_ELEVATOR_MODELS + "/step2", data, token);
    }
    async updateElevatorModelStep3(data, token) {
        return this.post(ApiUrl._API_UPDATE_ELEVATOR_MODELS + "/step3", data, token);
    }
    async updateElevatorModelStep4(data, token) {
        return this.post(ApiUrl._API_UPDATE_ELEVATOR_MODELS + "/step4", data, token);
    }

    async deleteElevatorModel(data, token) {
        return this.delete(ApiUrl._API_ELEVATOR_MODELS + "/delete/" + data, token);
    }

    async updateElevatorPart(data, token) {
        return this.put(ApiUrl._API_MODIFY_ELEVATOR_PART, data, token);
    }

    async createElevatorPart(data, token) {
        return this.post(ApiUrl._API_CREATE_ELEVATOR_PART, data, token);
    }

    async deleteElevatorPart(data, token) {
        return this.delete(ApiUrl._API_DELETE_ELEVATOR_PART+ "/" + data, token);
    }
}

export default ElevatorModelDao;
