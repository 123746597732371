import React, { useMemo, useState, useRef, useEffect, useCallback } from "react";
import ReactTable from "components/react-table/ReactTable";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Notiflix from "notiflix";
import { useHistory } from "react-router-dom";
import { InputTypes, SelectField, WebUrl, ApiKey } from "util/Constant";
import { Config, ConfigEnum } from "util/Config";
import ElevatorModelDao from "data/ElevatorModelDao";
import { Block } from "notiflix";
import { tryCatch, handleSetPageOptions } from "util/Utility";
import moment from "moment";

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default () => {
  const history = useHistory();
  const [agent, setAgent] = useState(null);
  const [elevatorModels, setElevatorModels] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const _tableRef = useRef();
  let configInstance = Config.getInstance();
  var token = configInstance.getValue(ConfigEnum._TOKEN);
  const [listQueryParams, setListQueryParams] = useState();
      let dao = new ElevatorModelDao();

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  const getElevatorModels = useCallback(
    async (params) => {
      Block.circle("#elevatorsTable");


      await dao.getElevatorModels(token,params,1).then((response) => {
        if (response[ApiKey._API_SUCCESS_KEY]) {
          var data = response[ApiKey._API_DATA_KEY];
          console.log(data);
          setElevatorModels(data);
          handleSetPageOptions(_tableRef, response[ApiKey._API_TOTAL_KEY] ?? 0);
        } else {
          Notiflix.Report.failure(
            "Error",
            "Failed to retrieve elevator models. Please try again later"
          );
        }
      });

      Block.remove("#elevatorsTable");
    }, [dao, token]);


    useEffect(() => {
      listQueryParams && getElevatorModels(listQueryParams);
    }, [listQueryParams]);


    const deleteElevatorModel = async (row) => {
      var id = row.id;
      let dao = new ElevatorModelDao();
      console.log(id);
      await dao.deleteElevatorModel(id, token).then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          Notiflix.Report.success("Success", "Elevator Model Deleted");
          getElevatorModels();
        } else {
          Notiflix.Report.failure(
            "Error",
            "Failed to delete customer. " + responseJson[ApiKey._API_MESSAGE_KEY]
          );
        }
      });
    };

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>

  const _COLUMN = useMemo(() => [
    {
      Header: "Serial Number",
      Cell: ({ row }) => <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}><b>{row.original.serial_number}</b></div>,
    },
    {
      Header: "Product Type",
      Cell: ({ row }) => (
        <>
          <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
             {row.original.product_type}
          </div>
        </>
      ),
    },
    {
      Header: "Model Type",
      Cell: ({ row }) => (
        <>
          <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
             {row.original.model_type}
          </div>
        </>
      ),
    },
    {
      Header: "Rated Load (kg)",
      Cell: ({ row }) => (
        <>
          <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
             {row.original.rated_load}
          </div>
        </>
      ),
    },
    {
      Header: "Rated Speed (mpm)",
      Cell: ({ row }) => (
        <>
          <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
             {row.original.rated_speed}
          </div>
        </>
      ),
    },
    {
      Header: "Number Of Stops",
      Cell: ({ row }) => (
        <>
          <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
             {row.original.number_of_stops}
          </div>
        </>
      ),
    },
    {
      Header: "JKKP Requirement",
      Cell: ({ row }) => (
        <>
          <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
             {row.original.jkkp_requirement == 1 ? "Yes" : "No"}
          </div>
        </>
      ),
    },
    {
      Header: "Creation Date",
      Cell: ({ row }) => (
        <>
          <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
             {moment(row.original.created_at).local().format("DD-MM-YYYY hh:mm:ss A")}
          </div>
        </>
      ),
    },
    {
      Header: "Modified Date",
      Cell: ({ row }) => (
        <>
          <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
             {moment(row.original.updated_at).local().format("DD-MM-YYYY hh:mm:ss A")}
          </div>
        </>
      ),
    },
    {
      Header: " ",
      Cell: ({ row }) => (
        <>
          <div
            className="btn-table"
            onClick={() =>
              history.push(
                WebUrl._ELEVATOR_MODELS_UPDATE.replace(
                  ":id",
                  row.original.serial_number
                ),
                { details: row.original }
              )
            }
          >
            <i className="fa fa-edit"></i>
          </div>
          <div
              className="btn-table last-btn"
              title="Delete Elevator Model"
              onClick={() => {
                Notiflix.Confirm.show(
                  "Confirmation",
                  "Do you sure to delete elevator model?",
                  "Yes",
                  "No",
                  function () {
                    deleteElevatorModel(row.original);
                  }
                );
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
        </>
      ),
      disableSortBy: true,
      disableFilters: true,
      style: { overflow: "visible", textAlign: "right" },
      sticky: "right",

    },
  ]);

  return (
    <>
      <div className="float-xl-right">
        <button
          type="type"
          className="btn btn-sm btn-themed"
          onClick={() =>
            history.push(
              WebUrl._ELEVATOR_MODELS_CREATE
            )
          }
        >
          <i className="fa fa-plus mr-1"></i>Add Elevator Model
        </button>
      </div>
      <h1 className="page-header">Elevator Model Management</h1>

      <div id="elevatorsTable">
        <ReactTable
          columns={_COLUMN}
          data={elevatorModels}
          getRowProps={(row) => {
            return {
              onDoubleClick: () =>
                history.push(
                  WebUrl._ELEVATOR_MODELS_UPDATE.replace(
                    ":id",
                    row.original.serial_number
                  ),
                  { details: row.original }
                ),
              onClick: (e) => {
                setSelectedRow(row.id);
              },
              class: row.id == selectedRow && "table-row-selected",
            };
          }}
          filterable
          canPreviousPage={true}
          canNextPage={true}
          disabledApiPagination={false}
          queryParams={listQueryParams}
          setQueryParams={setListQueryParams} 
          ref={_tableRef}
          successCallback= {() => {
            getElevatorModels(listQueryParams)}}
        />
      </div>
    </>
  );
};
